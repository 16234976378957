import React from "react";
import { format, parse } from "date-fns";

import { generateClientAddress } from "utils/general";

const MyProfileForm = (props) => {
  return (
    <>
      <div className="form-section">
        <div className="form-section-header">
          <div className="form-section-title">Driving information</div>
          <div className="form-section-action">
            <button onClick={(e) => props.setStep("driving")}>Edit</button>
          </div>
        </div> 
        <div className="form-section-content">
          <div className="form-section-row">
            <div className="field-name">License type:</div>
            <div className="field-value">{props?.data?.license_type}</div>
          </div> 
          <div className="form-section-row">
            <div className="field-name">Vehicle use:</div>
            <div className="field-value">{props?.data?.vehicle_use}</div>
          </div> 
        </div>
      </div>
          
      <div className="form-section">
        <div className="form-section-header">
          <div className="form-section-title">Occupation</div>
          <div className="form-section-action">
            <button onClick={(e) => props.setStep("occupation")}>Edit</button>
          </div>
        </div>
        <div className="form-section-content">
          <div className="form-section-row">
            <div className="field-name">Employment status:</div>
            <div className="field-value capitalize">{ props?.data?.employment_type }</div>
          </div>
          <div className="form-section-row">
            <div className="field-name">Length at current job:</div>
            <div className="field-value">{ props?.data?.job_years } years { props?.data?.job_months } months</div>
          </div>
          <div className="form-section-row">
            <div className="field-name">Annual salary:</div>
            <div className="field-value">
              £{parseInt(props?.data?.income)?.toLocaleString("en-GB")}
            </div>
          </div>
        </div>
      </div>

      <div className="form-section">
        <div className="form-section-header">
          <div className="form-section-title">Personal information</div>
          <div className="form-section-action">
            <button onClick={(e) => props.setStep("personal")}>Edit</button>
          </div>
        </div>
        <div className="form-section-content">
          <div className="form-section-row">
            <div className="field-name">Title:</div>
            <div className="field-value capitalize">{ props?.data?.title }</div>
          </div>
          <div className="form-section-row">
            <div className="field-name">Name:</div>
            <div className="field-value">
              {props?.data?.first_name} {props?.data?.last_name}
            </div>
          </div>
          <div className="form-section-row">
            <div className="field-name">Email address:</div>
            <div className="field-value">{props?.data?.email}</div>
          </div>
          <div className="form-section-row">
            <div className="field-name">Date of birth:</div>
            <div className="field-value">
              { props.data.dob ? (
                <>{ format(parse(props?.data?.dob, "yyyy-MM-dd", new Date()), "dd/MM/yyyy") }</>
              ) : (
                <>
                  {props?.data?.dob_day}/{props?.data?.dob_month}/{props?.data?.dob_year}
                </>
              )}
            </div>
          </div>
          <div className="form-section-row">
            <div className="field-name">Relationship status:</div>
            <div className="field-value capitalize">{ props?.data?.relationship_status }</div>
          </div>
          <div className="form-section-row">
            <div className="field-name">Address:</div>
            <div className="field-value">{ generateClientAddress(props?.data) }</div>
          </div>
          <div className="form-section-row">
            <div className="field-name">Time at current address:</div>
            <div className="field-value">{ props?.data?.current_years } years { props?.data?.current_months } months</div>
          </div>
          <div className="form-section-row">
            <div className="field-name">Living arrangements:</div>
            <div className="field-value capitalize">{ props?.data?.living_arrangements }</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyProfileForm;
