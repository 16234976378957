import React from "react";

import LenderLogo from "components/LenderLogo";

const Lender = (props) => {
  return (
    <div className="lender-card">
      <div className="lender-image">
        <LenderLogo image={props.lender.lenderLogo} />
      </div>
    </div>
  );
}

export default Lender;
