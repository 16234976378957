import React from "react";

import { ReactComponent as TickIcon } from "assets/images/icons/tick.svg";

const BenefitsList = (props) => {
  return (
    <>
      <div className="feature">
        <div className="feature-icon-title">
          <div className="feature-icon">
            <TickIcon />
          </div>
          <div className="feature-title">
            Authorised and regulated by the Financial Conduct Authority
          </div>
        </div>
      </div>

      <div className="feature">
        <div className="feature-icon-title">
          <div className="feature-icon">
            <TickIcon />
          </div>
          <div className="feature-title">
            Access pre-approved loan offers from a network of reputable lenders
          </div>
        </div>
      </div>

      <div className="feature">
        <div className="feature-icon-title">
          <div className="feature-icon">
            <TickIcon />
          </div>
          <div className="feature-title">
            Apply to an offer without affecting your credit score
          </div>
        </div>
      </div>
    </>
  );
};

export default BenefitsList;
