export const getOptionsArray = (options, exclude = []) => {
  return options.filter(option => {
    if (exclude.length > 0) {
      return !exclude.includes(option);
    }

    return true;
  }).map(option => {
    let label = (option === "Student Accommodation" ? "Student Housing" : option);

    return {
      value: option,
      label,
    }
  });
}
