import React from "react";

const InformationPage = (props) => {
  return (
    <div className="application-checkout-questions fixed-button-section">
      <h4>A few more questions to finalise your application</h4>
      <p className="text-grey">
        We just need to get some details from you based around your 
        employment, your banking and your affordability and then we’ll 
        be able to submit your application to your chosen lender.
      </p>

      <button onClick={props.onProceed} className="light-blue fixed-checkout-button">
        Continue
      </button>
    </div>
  );
}

export default InformationPage;
