import React, { useEffect, useState } from "react";

import { getApplicationStatus } from "services";
import Approved from "./Approved";
import Awaiting from "./Awaiting";
import Declined from "./Declined";
import Pending from "./Pending";
import Referred from "./Referred";

const DecisionPage = (props) => {
  const [ decision, setDecision ] = useState('pending');

  useEffect(() => {
    setDecision(props.decision);
  }, [props.decision]);

  useEffect(() => {
    const interval = setInterval(() => {
      getApplicationStatus(props.application.applicationGuid)
        .then(res => {
          if (res.quoteStatus !== "") {
            setDecision(res?.quoteStatus.toLowerCase());
            clearInterval(interval);
          }
        });
    }, 10000);

    return () => {
      clearInterval(interval);
    }
  }, []);

  const renderDecision = () => {
    switch(decision) {
      case "accepted":
      case "approved":
        return <Approved />
      case "pending":
        return <Pending />
      case "referred":
        return <Referred />
      case "declined":
        return <Declined onClick={props.onDeclineClick} />
      default:
        return <Awaiting />
    }
  }

  return (
    <>{ renderDecision() }</>
  );
}

export default DecisionPage;
