import React from "react";

import { ReactComponent as DeclinedIcon } from "assets/images/icons/declined.svg";

const Awaiting = (props) => {
  return (
    <div className="decision-page">
      <div className="decision-content">
        <div className="decision-title large">
          Awaiting the decision
        </div>
        <div className="decision-text">
          We will send you an email when we receive a decision, this shouldn’t be too long.
        </div>
      </div>
    </div>
  );
}

export default Awaiting;
