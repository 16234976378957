import React, { useEffect, useRef, useState } from "react";

const TermsAccordion = (props) => {
  const getClasses = () => {
    let classes = [];

    if (props.open) {
      classes.push("open");
    } else if (props.completed) {
      classes.push("completed");
    }

    return classes.join(" ");
  };

  return (
    <div
      className={`terms-section-container full-height rounded-md ${getClasses()}`}
    >
      <div className="terms-section">
        <div className="terms-section-header">
          <div className="text-xl font-bold">{props.title}</div>
          <div
            className="terms-expander"
            onClick={(e) => props.onExpand(props.name)}
          >
            View
          </div>
        </div>

        <div className="terms-section-body">
          <div className="text-sm font-bold block mt-2">{props.info}</div>

          <div
            dangerouslySetInnerHTML={{ __html: props.content }}
            className="border border-[#EAEAEA] rounded px-3 py-2 mt-4 overflow-y-auto"
          />

          <button
            className="dark full mt-3"
            onClick={(e) => props.onCompleted(props.name)}
          >
            I have read and accept
          </button>
        </div>
      </div>
    </div>
  );
};

TermsAccordion.defaultProps = {
  name: null,
  onCompleted: () => {},
};

export default TermsAccordion;
