import React, { useEffect, useState } from "react";

import Occupation from "components/forms/profile/Occupation";

const OccupationPage = (props) => {
  return (
    <Occupation {...props} />
  );
};

export default OccupationPage;
