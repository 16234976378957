import React from "react";

const PageTitle = ({ title, subText, logo }) => {
  return (
    <div className="page-title-container">
      <div className="page-title-image-container">
        <h1 className="page-title">{title}</h1>
        {logo && <img src={logo} className="page-title-image" />}
      </div>
      <div>
        <p className="page-subtext">{subText}</p>
      </div>
    </div>
  );
};

export default PageTitle;
