import React from "react";

import DrivingInfo from "components/forms/profile/DrivingInfo";

const DrivingInfoPage = (props) => {

  return (
    <DrivingInfo {...props} />
  );
};

export default DrivingInfoPage;
