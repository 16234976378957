import React from "react";

const ProgressTracker = (props) => {
  const handleClick = (step) => {
    if (step.completed) {
      props.onClick(step);
    }
  }

  return (
    <div className="progress-tracker">
      { props.steps.map(step => (
        <div className={`progress-tracker-step ${step.completed && "selected"}`} key={step.label} onClick={e => handleClick(step)}>
          <div className="progress-tracker-step-icon">
            { step.icon }
          </div>
          <div className="progress-tracker-step-text">
            { step.label }
          </div>
        </div>
      ))}
    </div>
  )
}

ProgressTracker.defaultProps = {
  steps: [],
  selected: null,
  onClick: () => {},
}

export default ProgressTracker;
