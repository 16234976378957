import React from "react";

import { b64toBlobUrl } from "utils/images";

const LenderLogo = (props) => {
  const handleOnClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  }

  return (
    <img
      src={b64toBlobUrl(props.image)}
      className={`lender-logo ${props.onClick ? "cursor-pointer" : ""}`}
      alt="logo"
      onClick={handleOnClick}
    />
  );
}

export default LenderLogo;
