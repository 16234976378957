import React from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as ClockIcon } from "assets/images/icons/clock.svg";

const Pending = (props) => {
  const navigate = useNavigate();

  return (
    <div className="decision-page">
      <div className="decision-content">
        <div className="decision-icon">
          <ClockIcon />
        </div>
        <div className="decision-title">
          The finance application has been submitted and we are awaiting a decision
        </div>
        <div className="decision-text">
          Sometimes lenders take longer than usual to process a decision. This could take up to 30 minutes.
        </div>
      </div>

      <div className="decision-action">
        <button className="green mt-auto" onClick={e => navigate('/offers')}>Finish</button>
      </div>
    </div>
  );
}

export default Pending;
