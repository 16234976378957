import RetrieveQuotePage from "pages/retrieve/index";

const retrieveRoutes = [
  {
    name: "retrieve",
    path: "/retrieve/token/:token",
    component: <RetrieveQuotePage />
  },
  {
    name: "retrieve",
    path: "/retrieve/:quote",
    component: <RetrieveQuotePage />
  },
];

export default retrieveRoutes;
