import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { getErrorLabels } from "utils/errors";
import { getMetaData, sendVerificationCode } from "services";
import { setMetaData } from "store/actions/general";
import { setUser } from "store/actions/auth";
import Error from "components/Error";
import Webpass, { webpassBase } from "services/webpass";

import { ReactComponent as AppleIcon } from "assets/images/icons/apple.svg";
import { ReactComponent as EmailIcon } from "assets/images/icons/email.svg";
import { ReactComponent as PhoneIcon } from "assets/images/icons/phone.svg";
import { ReactComponent as KeyIcon } from "assets/images/icons/key_icon.svg";
import { ReactComponent as FingerprintIcon } from "assets/images/icons/fingerprint.svg";
import { ReactComponent as FaceIdIcon } from "assets/images/icons/faceid.svg";

import googleIcon from "assets/images/icons/google.png";

const RetrieveQuoteForm = (props) => {
  const [registerData, setRegisterData] = useState({});
  const [registerMethod, setRegisterMethod] = useState("phone");
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const [supportsPasskeys, setSupportsPasskeys] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const phoneValidation = yup.object({
    phone: yup
      .string()
      .matches(/^(44|0)7[0-9]{9}$/, "Please enter a valid phone number")
      .required(),
  });

  const emailValidation = yup.object({
    email: yup.string().email("Please enter a valid email address").required(),
  });

  useEffect(() => {
    getMetaData().then((res) => {
      dispatch(setMetaData(res));
    });

    setSupportsPasskeys(Webpass.isSupported());
  }, []);

  const handleRegisterChange = (e) => {
    setRegisterData({
      ...registerData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRegister = () => {
    let schema;

    if (registerMethod === "phone") {
      schema = phoneValidation;
    } else {
      schema = emailValidation;
    }

    const payload = {
      [registerMethod]: registerData[registerMethod],
    };

    schema
      .validate(payload, { abortEarly: false })
      .then((res) => {
        sendVerificationCode({ [registerMethod]: registerData[registerMethod], type: "retrieve" })
          .then((res) => {
            props.onProceed(payload, res);
          })
          .catch((err) => {
            setError(err.response.data.message ?? err.message);
          });
      })
      .catch((err) => {
        setErrors(getErrorLabels(err));
      });
  };

  const handlePasskeyLogin = async () => {
    const { user, success, error, token } = await webpassBase().assert(
      "/journey/webauthn/login/options",
      "/journey/webauthn/login"
    );

    if (success) {
      dispatch(setUser(user));

      props.onPasskeyLogin(token);
    } else {
      alert("We are able to login with the passkey provided. Please try again");
    }
  };

  return (
    <div className="guest-page">
      <div className="guest-content">
        <div className="guest-title">
          Get <span>pre-approved</span> offers with just your mobile number
        </div>

        <div className="form-container h-auto">
          <div className="form-item mb-3">
            {registerMethod === "phone" ? (
              <>
                <label className="thin">
                  Get started with your mobile number
                </label>
                <input
                  type="phone"
                  name="phone"
                  onChange={handleRegisterChange}
                  placeholder="Enter mobile number"
                />
                <Error errors={errors} name="phone" />
              </>
            ) : (
              <>
                <label className="thin">
                  Get started with your email address
                </label>
                <input
                  type="email"
                  name="email"
                  onChange={handleRegisterChange}
                  placeholder="Enter email address"
                />
                <Error errors={errors} name="email" />
              </>
            )}

            {error && <p className="form-error">{error}</p>}
          </div>

          <div className="form-item mb-2">
            <button className="light-blue full" onClick={handleRegister}>
              Continue
            </button>
          </div>

          <div className="or-container">Or</div>

          <div className="buttons-list mb-0">
            <button className="login-button full">
              <img src={googleIcon} />
              <p>Sign in with Google</p>
            </button>

            {supportsPasskeys && (
              <button
                className="login-button full"
                onClick={handlePasskeyLogin}
              >
                <FaceIdIcon />
                <FingerprintIcon />
                <p>Sign in with Passkey</p>
              </button>
            )}

            {registerMethod === "phone" ? (
              <button
                onClick={(e) => setRegisterMethod("email")}
                className="login-button full"
              >
                <EmailIcon />
                <p>Sign in with Email</p>
              </button>
            ) : (
              <button
                onClick={(e) => setRegisterMethod("phone")}
                className="login-button full"
              >
                <PhoneIcon />
                <p>Sign in with Telephone</p>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RetrieveQuoteForm;
