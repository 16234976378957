import React, { useState, useEffect } from "react";
import LoaderComponent from "components/loader/Loader.jsx";

export function useLoading(initialLoadingState = true, delay = 0) {
  const [isLoading, setIsLoading] = useState(initialLoadingState);

  useEffect(() => {
    const delayTimeout = setTimeout(() => {
      setIsLoading(false);
    }, delay);

    return () => clearTimeout(delayTimeout);
  }, [delay]);

  return isLoading;
}

export function withLoading(Component, initialLoadingState = true, delay = 0) {
  return function WithLoadingComponent(props) {
    const isLoading = useLoading(initialLoadingState, delay);

    return isLoading ? <LoaderComponent /> : <Component {...props} />;
  };
}

export const useDataLoading = (initialLoadingState = true) => {
  const [loading, setLoading] = useState(initialLoadingState);

  return { loading, setLoading };
}
