import { combineReducers } from "redux";
import { authReducer } from "store/reducers/auth";
import { generalReducer } from "store/reducers/general";

const rootReducer = combineReducers({
  auth: authReducer,
  general: generalReducer,
});

export default rootReducer;
