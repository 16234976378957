import CheckoutLayout from "components/layouts/CheckoutLayout";

import DashboardPage from "pages/auth/Dashboard";
import HelpCenter from "pages/auth/HelpCenter";
import MyOffers from "pages/auth/MyOffers";
import MyProfile from "pages/auth/MyProfile";
import Notifications from "pages/auth/Notifications";
import OfferDetailsPage from "pages/auth/offers/OfferDetailsPage";
import QuoteCheckoutPage from "pages/auth/offers/QuoteCheckoutPage";
import Retailers from "pages/auth/Retailers";
import RetailersOffers from "pages/auth/RetailersOffers";
import SubmittedApplications from "pages/auth/SubmittedApplications";

const authRoutes = [
  {
    name: "Home",
    path: "/",
    component: <DashboardPage />,
  },
  {
    name: "MyOffers",
    path: "/offers",
    component: <MyOffers />,
  },
  {
    name: "OfferDetails",
    path: "/offers/:id",
    component: <OfferDetailsPage />,
    layout: CheckoutLayout,
  },
    {
    name: "OfferDetails",
    path: "/offers/:id/confirm",
    component: <OfferDetailsPage confirmAddress />,
    layout: CheckoutLayout,
  },
  {
    name: "QuoteCheckout",
    path: "/offers/:offer/checkout/:id",
    component: <QuoteCheckoutPage />,
    layout: CheckoutLayout,
  },
  {
    name: "SubmittedApplication",
    path: "/applications/submitted",
    component: <SubmittedApplications />,
  },
  {
    name: "Retailers",
    path: "/retailers",
    component: <Retailers />,
  },
  {
    name: "Retailers Offers",
    path: "/retailers/:id/offers",
    component: <RetailersOffers />,
  },
  {
    name: "MyProfile",
    path: "/myprofile",
    component: <MyProfile />,
  },
  {
    name: "Notifications",
    path: "/notifications",
    component: <Notifications />,
  },
  {
    name: "HelpCenter",
    path: "/helpcenter",
    component: <HelpCenter />,
  },
];

export default authRoutes;
