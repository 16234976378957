import React from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as ReferredIcon } from "assets/images/icons/referred.svg";

const Referred = (props) => {
  const navigate = useNavigate();

  return (
    <div className="decision-page">
      <div className="decision-content">
        <div className="decision-icon">
          <ReferredIcon />
        </div>
        <div className="decision-title">
          The finance application has been referred.
        </div>
        <div className="decision-text">
          The lender will be in touch with you shortly with further questions regarding the application.
        </div>
      </div>

      <div className="decision-action">
        <button className="green mt-auto" onClick={e => navigate('/offers')}>Finish</button>
      </div>
    </div>
  );
}

export default Referred;
