import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import generalTypes from "store/types/general";

const initialState = {
  metaData: {},
  currentQuote: {},
}

export const generalReducer = persistReducer(
  { storage, key: "lender", whitelist: ['metaData'] },
  (state = initialState, { type, payload }) => {
    switch (type) {
      case generalTypes.SET_METADATA:
        return {
          ...state,
          metaData: payload,
        };

      case generalTypes.SET_CURRENT_QUOTE:
        return {
          ...state,
          currentQuote: payload,
        };

      default:
        return state;
    }
  }
);
