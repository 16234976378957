import React, { useEffect, useState } from "react";

import { normaliseNumber } from "utils/general";
import { getQuoteSchema, updateQuote } from "services";
import AddPartExchange from "./AddPartExchange";
import QuoteDetails from "./QuoteDetails";

import { ReactComponent as ChevronUpIcon } from "assets/images/icons/chevron_up.svg";

const QuoteBuilder = (props) => {
  const [addPartExchange, setAddPartExchange] = useState(false);
  const [editQuote, setEditQuote] = useState({});
  const [hasShown, setHasShown] = useState(false);
  const [quoteLoading, setQuoteLoading] = useState(false);
  const [showVrmInput, setShowVrmInput] = useState(false);
  const [show, setShow] = useState(false);
  const [termOptions, setTermOptions] = useState([12, 18, 24, 30, 36, 42, 48, 54, 60]);

  useEffect(() => {
    getQuoteSchema().then((res) => {
      setTermOptions(res.options.term);
    });
  }, []);

  useEffect(() => {
    if (props.mobile && show === false && hasShown) {
      props.onClose();
      setAddPartExchange(false);
    } else if (show) {
      setHasShown(true);
    }
  }, [show]);

  useEffect(() => {
    if (props.offer) {
      setEditQuote(props.offer);
    }
  }, [props.offer, props.reload]);

  useEffect(() => {
    props.onQuoteChange({
      term: normaliseNumber(editQuote.term),
      mileage: normaliseNumber(editQuote?.annualMileage),
      deposit: normaliseNumber(editQuote.deposit),
      includeEquity: editQuote.includeEquity,
    });
  }, [editQuote]);

  const handleFieldChange = (field) => {
    props.setQuoteChanged(field);
  }

  const shouldShow = () => {
    if (props.mobile) {
      return show;
    }

    return true;
  }

  const handleOnUnderstand = (val) => {
    props.onUnderstand(val);

    if (props.mobile && val) {
      setShow(false);
    }
  }

  const handleUpdateQuote = () => {
    let data = {
      term: normaliseNumber(editQuote.term),
      mileage: normaliseNumber(editQuote?.annualMileage),
      deposit: normaliseNumber(editQuote.deposit),
      includeEquity: editQuote.includeEquity,
    };

    if (!editQuote.includeEquity) {
      data.pxVehcile = null;
    }

    props.onUpdate(data);
  };

  const onPxUpdate = (res) => {
    props.onPxUpdate(res);

    if (props.mobile) {
      setShow(false);
    }
  }

  const getClassNames = () => {
    let classes = [];

    if (props.mobile) {
      classes.push('flex lg:hidden mobile');

      if (show) {
        classes.push('mobile-open');
      } else {
        classes.push('mobile-closed');
      }
    } else {
      classes.push('hidden lg:flex');
    }

    return classes.join(' ');
  }

  return (
    <div className={`quote-builder-container ${getClassNames()}`}>
        { shouldShow() ? (
        <>    
          { props.mobile && (
            <div className="mobile-builder-close" onClick={e => setShow(false)}>
              Close controls

              <ChevronUpIcon />
            </div>
          )}

          {addPartExchange === false ? (
            <QuoteDetails
              editQuote={editQuote}
              handleUpdateQuote={handleUpdateQuote}
              setEditQuote={setEditQuote}
              setAddPartExchange={setAddPartExchange}
              setShowVrmInput={setShowVrmInput}
              setShowRecalcMsg={props.setShowRecalcMsg}
              showRecalcMsg={props.showRecalcMsg}
              quoteLoading={quoteLoading}
              termOptions={termOptions}
              onQuoteReset={props.onQuoteReset}
              quoteChanged={props.quoteChanged}
              onFieldChange={handleFieldChange}
              {...props}
              onUnderstand={handleOnUnderstand}
            />
          ) : (
            <AddPartExchange
              editQuote={editQuote}
              setEditQuote={setEditQuote}
              addPartExchange={addPartExchange}
              setAddPartExchange={setAddPartExchange}
              quoteLoading={quoteLoading}
              setQuoteLoading={setQuoteLoading}
              showVrmInput={showVrmInput}
              setShowVrmInput={setShowVrmInput}
              {...props}
              onPxUpdate={onPxUpdate}
            />
          )}
        </>
      ) : (
        <div className="mobile-builder-toggle" onClick={e => setShow(true)}>
          Build your offer
          <ChevronUpIcon />
        </div>
      )}
    </div>
  );
};

QuoteBuilder.defaultProps = {
  mobile: false,
  onQuoteChange: () => {},
  onClose: () => {},
}

export default QuoteBuilder;
