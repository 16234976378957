export const normaliseNumber = (val) => {
  if (val && typeof val === "string") {
    return parseInt(val.replace(/,/g, '')
      .replace('£', ''));
  }

  return val;
}

export const monthsToDuration = (totalMonths) => {
  let months = 0;
  let years = 0;

  if (totalMonths) {
    years = Math.floor(totalMonths / 12);
    months = totalMonths - (years * 12);
  }

  return {
    months,
    years,
  };
}

export const generateClientAddress = (data) => {
  let address = [];

  if (data?.door_number) {
    address.push(data.door_number);
  }

  if (data?.street_name) {
    address.push(data.street_name);
  }

  if (data?.city) {
    address.push(data.city);
  }

  if (data?.postcode) {
    address.push(data.postcode);
  }

  return address.join(', ');
}

export const applicationToProfile = (application) => {
  let addressDuration = monthsToDuration(parseInt(application?.client?.addressList[0]?.monthsAtAddress));
  let employmentDuration = monthsToDuration(parseInt(application?.client?.monthsAtJob));

  let profile = {
    current_months: addressDuration.months,
    current_years: addressDuration.years,
    dob: application?.client?.dateOfBirth,
    email: application?.client?.clientEmail,
    employment_type: application?.client?.employmentStatus,
    first_name: application?.client?.firstName,
    income: application?.client?.income,
    job_months: employmentDuration.months,
    job_years: employmentDuration.years,
    last_name: application?.client.lastName,
    license_type: application?.client?.drivingLicenceType,
    living_arrangements: application?.client?.typeOfResidence,
    door_number: application?.client?.addressList[0]?.addressLine1,
    street_name: application?.client?.addressList[0]?.addressLine2,
    city: application?.client?.addressList[0]?.addressLine4,
    postcode: application?.client?.addressList[0]?.postCode,
    relationship_status: application?.client?.maritalStatus,
    title: application?.client?.title,
    vehicle_use: application?.vehicle?.vehcileUse,
  };

console.log('profile', profile);

  return profile;
}
