import LoginPage from "pages/guest/login";
import RegisterPage from "pages/guest/register/index";

const guestRoutes = [
  {
    name: "login",
    path: "/",
    component: <LoginPage />,
  },
  {
    name: "register",
    path: "/register",
    component: <RegisterPage />,
  },
];

export default guestRoutes;
