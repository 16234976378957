import { useEffect } from "react";
import {
  Navigate,
  Routes,
  Route,
  useLocation,
  matchRoutes,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { useSelector } from "react-redux";

import { authRoutes, guestRoutes, retrieveRoutes } from "routes";
import { GoogleOAuthProvider } from '@react-oauth/google';
import AuthLayout from "components/layouts/Auth";
import CheckoutLayout from "components/layouts/CheckoutLayout";
import GuestLayout from "components/layouts/Guest";
import Retailers from "pages/auth/Retailers";
import RetrieveLayout from "components/layouts/Retrieve";

const App = () => {
  const token = useSelector((state) => state?.auth?.token);

  const location = useLocation();
  const isRetrieve = location.pathname.match(/^\/retrieve/);
  const result = matchRoutes(authRoutes, location);
  const route = (result && result.length > 0 ? result[0]?.route : null);

  const navigate = useNavigate();
  const [ searchParams ] = useSearchParams();

  const AuthLayoutOverride = (
    route?.layout ?? AuthLayout
  )

  useEffect(() => {
    if (searchParams.get('redirect') && token) {
      navigate(searchParams.get('redirect'));
    }
  }, [token]);

  return (
    <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
      { isRetrieve ? (
        <RetrieveLayout>
          <Routes>
            {retrieveRoutes.map(route => (
              <Route
                path={route.path}
                element={route.component}
                key={route.name}
              />
            ))}
          </Routes>
        </RetrieveLayout>
      ) : (
        <>
          {token ? (
            <AuthLayoutOverride>
              <Routes>
                {authRoutes.map((route) => (
                  <Route
                    path={route.path}
                    element={route.component}
                    key={route.name}
                  />
                ))}
                <Route path="*" element={<Navigate to="/" replace />} />
                <Route exact path="/" component={Retailers} />
              </Routes>
            </AuthLayoutOverride>
          ) : (
            <GuestLayout>
              <Routes>
                {guestRoutes.map((route) => (
                  <Route
                    path={route.path}
                    element={route.component}
                    key={route.name}
                  />
                ))}
                <Route path="*" element={<Navigate to={`/?redirect=${window.location.pathname}`} replace />} />
              </Routes>
            </GuestLayout>
          )}
        </>
      )}
    </GoogleOAuthProvider>
  );
};

export default App;
