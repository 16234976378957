import React from "react";
import PageTitle from "components/misc/PageTitle";
import { useLoading } from "components/loader/LoaderHook.jsx";
import Loader from "components/Loader";

const HelpCenter = () => {
  const isLoading = useLoading(true, 2000);

  return (
    <>
      {isLoading ? (
        <div className="dash-content flex items-center justify-center h-100">
          <Loader />
        </div>
      ) : (
        <>
          <PageTitle
            title="Help center"
          />
        </>
      )}
    </>
  );
};

export default HelpCenter;
