export const formatCurrency = (val, precision = 2) => {
  if (val) {
    return val.toLocaleString("en-GB", {
      style: "currency",
      currency: "GBP",
      maximumFractionDigits: precision,
    });
  }

  return (0).toLocaleString('en-GB', {
    style: "currency",
    currency: "GBP",
    maximumFractionDigits: precision,
  });
}
