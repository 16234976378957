import React, { useState } from "react";

const ButtonOptions = (props) => {
  const [ showMore, setShowMore ] = useState(false);

  const handleOnClick = (option) => {
    props.onClick(props.name, option);
  }

  const shouldShow = (index) => {
    return !props.show || showMore || index < props.show;
  }

  const showMoreOption = () => {
    return props.show && !showMore && props.options.length > props.show;
  }

  return (
    <div className={`button-options ${showMore ? "showing-all" : ""} ${props.className}`}>
      { props.options.map((option, index) => (
        <React.Fragment key={option.value}>
          { shouldShow(index) && (
            <div
              className={`button-option ${props.selected === option.value ? "selected" : ""}`}
              onClick={e => handleOnClick(option)}
              key={option.value}
            >
              { option.label }
            </div>
          )}
        </React.Fragment>
      ))}

      { showMoreOption() && (
        <div
          className="button-option"
          onClick={e => setShowMore(true)}
        >
          Show more
        </div>
      )}
    </div>
  );
}

ButtonOptions.defaultProps = {
  name: null,
  options: [],
  selected: null,
  show: null,
  onClick: () => {},
  className: null,
}

export default ButtonOptions;
