import React from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as DeclinedIcon } from "assets/images/icons/declined.svg";

const Declined = (props) => {
  const navigate = useNavigate();

  return (
    <div className="decision-page">
      <div className="decision-content">
        <div className="decision-icon">
          <DeclinedIcon />
        </div>
        <div className="decision-title">
          The finance application has been declined.
        </div>
        <div className="decision-text">
          The lender has declined the finance application, please go back and try other offers from different lenders.
        </div>
      </div>

      <div className="decision-action">
        <button
          className="green mt-auto"
          onClick={props.onClick}
        >
          Select another lender
        </button>
      </div>
    </div>
  );
}

export default Declined;
