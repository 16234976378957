import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authTypes from "store/types/auth";

const initialState = {
  user: null,
  token: null,
  expiry: null,
  registerData: {},
  loginData: {},
}

export const authReducer = persistReducer(
  { storage, key: "auth", whitelist: ["token", "expiry", "user"] },
  (state = initialState, { type, payload }) => {
    switch (type) {
      case authTypes.LOGIN_SUCCESSFUL:
        return {
          ...state,
          token: payload.token,
          expiry: payload.expiryDate,
          registerData: {},
        };

      case authTypes.SET_USER:
        return {
          ...state,
          user: payload,
          registerData: {},
        };

      case authTypes.LOGOUT:
        return {
          ...state,
          token: null,
          expiry: null,
          user: null,
          registerData: {},
        };

      case authTypes.SET_REGISTER_DATA:
        return {
          ...state,
          registerData: payload,
        }

      case authTypes.SET_LOGIN_DATA:
        return {
          ...state,
          loginData: payload,
        }

      default:
        return state;
    }
  }
);
