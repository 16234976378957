import axios from "./base"

export const convertQuote = (id) => {
  return axios.get(`/journey/quote/retrieve/${id}`).then(res => res.data);
}

export const getDealersList = () => {
  return axios.get('/journey/retailers').then(res => res.data);
}

export const getDealerOffers = (id) => {
  return axios.get(`/journey/retailers/${id}`).then(res => res.data);
}

export const getMyOffers = () => {
  return axios.get('/journey/offers').then(res => res.data);
}

export const submittedApplications = () => {
  return axios.get('/journey/offers/submitted').then(res => res.data);
}

export const getOffer = (id, search = false) => {
  return axios.get(`/journey/offers/${id}`, { params: { search }}).then(res => res.data);
}

export const getOtherLenders = (id) => {
  return axios.get(`/journey/offers/${id}/lenders`).then(res => res.data);
}

export const getQuoteSchema = () => {
  return axios.get('/journey/quote/schema').then(res => res.data);
}

export const vrmSearch = (params) => {
  return axios.post('/journey/quote/vrm', params).then(res => res.data);
}

export const getPxValuation = (params) => {
  return axios.post('/journey/quote/vrm/valuation', params).then(res => res.data);
}

export const addPx = (id, params) => {
  return axios.post(`/journey/quote/${id}/px`, params).then(res => res.data);
}

export const hasPxRequest = (id) => {
  return axios.get(`/journey/quote/${id}/px/check`).then(res => res.data);
}

export const requestPx = (id, params) => {
  return axios.post(`/journey/quote/${id}/px/request`, params).then(res => res.data);
}

export const updateQuote = (id, params) => {
  params['_method'] = 'put';

  return axios.post(`/journey/quote/${id}`, params).then(res => res.data);
}

export const selectQuote = (id) => {
  return axios.post(`/journey/quote/${id}/select`).then(res => res.data);
}

export const getLenderQuestions = (id) => {
  return axios.post(`/journey/quote/${id}/questions`).then(res => res.data);
}

export const saveBankingDetails = (id, params) => {
  return axios.post(`/journey/quote/${id}/banking`, params).then(res => res.data);
}

export const submitApplication = (id, params) => {
  return axios.post(`/journey/quote/${id}/submit`, params).then(res => res.data);
}

export const getApplicationStatus = (id) => {
  return axios.get(`/journey/quote/${id}/status`).then(res => res.data);
}

export const setApplicationOccupation = (id, params) => {
  return axios.post(`/journey/quote/${id}/occupation`, params).then(res => res.data);
}

export const getSentQuote = (token) => {
  return axios.get(`/journey/token/${token}`).then(res => res.data);
}

export const getDealerFromGuid = (params) => {
  return axios.get('/journey/application/guid', { params }).then(res => res.data);
}

export const getApplicationData = () => {
  return axios.get('/journey/application/data').then(res => res.data);
}

export const clearPxRequests = (id) => {
  return axios.post(`/journey/quote/${id}/px/remove`).then(res => res.data);
}
