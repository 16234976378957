import Webpass from "https://cdn.jsdelivr.net/npm/@laragear/webpass@2/dist/webpass.mjs"
import store from "store";

const getFromStore = () => {
  const state = store.getState();

  return state?.auth?.token ? `Bearer ${state.auth?.token}` : "";
};

export const webpassBase = () => {
  return Webpass.create({
    method: "post",
    redirect: "error",
    baseURL: `${process.env.REACT_APP_API_BASE}`,
    findCsrfToken: false,
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": getFromStore(),
    },
  });
};

export default Webpass
