import React from "react";

const InvalidTokenPage = (props) => {
  return (
    <div className="guest-page">
      <h4 className="guest-title">Invalid token</h4>
      <p className="info-text">
        We've been unable to find the application provided. Please ensure 
        that the email you've registered with matches the email address
        provided to the dealer.
      </p>

      <button onClick={e => window.location.reload(true)} className="light-blue mt-4">
        Back
      </button>
    </div>
  );
}

export default InvalidTokenPage;
