import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getDealerOffers } from "services";
import { useDataLoading } from "components/loader/LoaderHook.jsx";
import Loader from "components/Loader";
import Offer from "components/offers/Offer";
import PageTitle from "components/misc/PageTitle";

const RetailersOffers = () => {
  const { loading, setLoading } = useDataLoading(true);
  const { id } = useParams();

  const [offers, setOffers] = useState([]);
  const [retailer, setRetailer] = useState(null);
  const [showArchiveButton, setShowArchiveButton] = useState(false);

  useEffect(() => {
    getDealerOffers(id)
      .then(res => {
        setOffers(res.data?.quotes ?? []);
        setRetailer({
          id: res.data?.id,
          name: res.data?.name,
          logo: res.data?.logo,
        });
        setLoading(false);
      });
  }, []);

  if (!retailer && !loading) {
    return (
      <div className="cant-find-retailer">
        We can't find the retailer you're looking for or you've archived all
        your quotes. Please go back and by clicking retailers in the menu.
      </div>
    );
  }

  const handleShowArchiveQuotesButton = () => {
    setShowArchiveButton((prev) => !prev);
  };

  const handleArchiveQuotes = (offerId) => {
    // Archive quote
  };

  return (
    <>
      {loading ? (
        <div className="dash-content flex items-center justify-center h-100">
          <Loader />
        </div>
      ) : (
        <>
          <div className="offers-title-button">
            <PageTitle
              title="Retailers Offers"
              logo={retailer.logo}
            />
            <button
              className="archive-button"
              onClick={handleShowArchiveQuotesButton}
            >
              {showArchiveButton ? "Show quotes" : "Archive quotes"}
            </button>
          </div>
          <div className="dash-content my-offers-content">
            <div className="offers-grid">
              {offers
                .map((offer) => (
                  <Offer
                    key={offer.id}
                    offer={offer}
                    showArchiveButton={showArchiveButton}
                    onArchive={handleArchiveQuotes}
                  />
                ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RetailersOffers;
