import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { clearCurrentQuote } from "store/actions/general";
import { useDataLoading } from "components/loader/LoaderHook";
import Loader from "components/Loader";
import Offer from "components/offers/Offer";
import PageTitle from "components/misc/PageTitle";

import { getMyOffers } from "services";

const MyOffers = () => {
  const dispatch = useDispatch();

  const { loading, setLoading } = useDataLoading(true);

  const [offers, setOffers] = useState([]);

  useEffect(() => {
    getMyOffers().then((res) => {
      setOffers(res.data ?? []);
      setLoading(false);
    });

    dispatch(clearCurrentQuote());
  }, []);

  return (
    <div
      className={`dash-content no-scroll my-offers-content ${
        loading ? "flex items-center justify-center h-100" : ""
      }`}
    >
      {loading ? (
        <Loader size={120} />
      ) : (
        <>
          <PageTitle
            title="My offers"
          />
          <div className="offers-grid">
            {offers.map((offer) => (
              <Offer key={offer.id} offer={offer} showStatus />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default MyOffers;
