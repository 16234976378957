import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { webpass } from "services/webpass";
import { setLoginData as setLoginStateData } from "store/actions/auth";
import ConfirmationCodePage from "pages/guest/login/ConfirmationCodePage";
import LoadingPage from "pages/guest/login/LoadingPage";
import LoginForm from "components/forms/LoginForm";

const LoginPage = (props) => {
  const dispatch = useDispatch();

  const [ loginData, setLoginData ] = useState({});
  const [ step, setStep ] = useState("login");
  const [ token, setToken ] = useState({});

  const loginStateData = useSelector(state => state?.auth?.loginData);

  useEffect(() => {
    if (Object.values(loginStateData).length > 0) {
      setLoginData(loginStateData);
      setStep("2fa");
      dispatch(setLoginStateData({}));
    }
  }, [loginStateData]);

  const handleOnLogin = (payload, response) => {
    setLoginData({
      ...payload,
      ...response
    });
    setStep("2fa");
  }

  const handleOnVerify = (payload) => {
    setStep("loading");
    setToken(payload);
  }

  return (
    <>
      { step === "login" ? (
        <LoginForm
          onProceed={handleOnLogin}
          onPasskeyLogin={handleOnVerify}
        />
      ) : step === "2fa" ? (
        <ConfirmationCodePage
          data={loginData}
          onProceed={handleOnVerify}
        />
      ) : step === "loading" ? (
        <LoadingPage token={token} />
      ) : (
        <></>
      )}
    </>
  );
}

export default LoginPage;
