import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { registerUser } from "services";
import { setUser } from "store/actions/auth";
import MyProfileForm from "components/forms/MyProfileForm";

const ReviewDetailsPage = (props) => {
  const dispatch = useDispatch();

  const onProceed = () => {
    registerUser(props.data).then((res) => {
      dispatch(setUser(res.data));
      props.onProceed();
    });
  };

  return (
    <div className="guest-page">
      <div className="guest-title">Review details</div>
      <div className="guest-page-info hidden lg:block">
        Let’s quickly review your details to make sure we’re all good and then
        we’ll get to your offers (after a few legal requirements!)
      </div>

      <div className="guest-content ">
        <div className="full-height">
          <MyProfileForm data={props.data} setStep={props.setStep} />
        </div>

        <div className="form-item mt-auto">
          <button className="light-blue full" onClick={onProceed}>
            Looks good to me - Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewDetailsPage;
