import React from "react";
import { Dropdown } from "react-bootstrap";

const OfferSort = (props) => {

  const handleOnChange = (e, value) => {
    e.preventDefault();
    props.onChange(value);
  }

  const getLabel = () => {
    switch (props.selected) {
      case 'monthly':
        return 'Monthly price';
      case 'apr':
        return 'APR';
      case 'total':
        return 'Total payable';
    }
  }

  return (
    <>
      <div className="offer-filter justify-content-end">
        Sort by:

        <a
          href="#"
          onClick={e => handleOnChange(e, 'monthly')}
          className={props.selected === "monthly" ? "selected" : ""}
        >
          Monthly price
        </a>

        <a
          href="#"
          onClick={e => handleOnChange(e, 'apr')}
          className={props.selected === "apr" ? "selected" : ""}
        >
          APR
        </a>

        <a
          href="#"
          onClick={e => handleOnChange(e, 'total')}
          className={props.selected === "total" ? "selected" : ""}
        >
          Total amount payable
        </a>
      </div>

      <div className="offer-filter-mobile justify-content-end">
        <Dropdown onSelect={(val, e) => handleOnChange(e, val)}>
          <Dropdown.Toggle>
            Sort by: { getLabel() }
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item eventKey="monthly">Monthly price</Dropdown.Item>
            <Dropdown.Item eventKey="apr">APR</Dropdown.Item>
            <Dropdown.Item eventKey="total">Total payable</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
}

OfferSort.defaultProps = {
  selected: "all",
  onChange: () => {},
};

export default OfferSort;
