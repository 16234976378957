export const getErrorLabels = (err, prefix = "") => {
  let valErrors = {};
      
  err.inner.map(error => {
    let fullPath = error.path;

    if (prefix) {
      fullPath = `${prefix}.${error.path}`;
    }

    let friendlyFieldName = error.params.label ?? capitaliseString(error.path.replace('_', ' '));

    if (["required", "nullable", "optionality", "typeError"].includes(error.type)) {
      valErrors[fullPath] = "This field is required";
    } else if (error.type === "length") {
      valErrors[fullPath] = `Field must ${error.params.length} characters`;
    } else if (error.type === "oneOf") {
      valErrors[fullPath] = "Please select an option";
    } else if (error.type === "min" && error.path === "income") {
      valErrors[fullPath] = "This looks low. Please enter your annual income before tax";
    } else if (error.type == "max") {
      valErrors[fullPath] = `${friendlyFieldName} cannot be greater than ${error.params.max}`;
    } else if (error.type == "min") {
      valErrors[fullPath] = `${friendlyFieldName} must be greater than ${error.params.min}`;
    } else if (['matches', 'email'].includes(error.type)) {
      valErrors[fullPath] = error.message;
    } else if (error.type === "is-date-valid") {
      valErrors[fullPath] = error.message;
    }
  });     

  return valErrors;                  
}

const capitaliseString = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
