import React, { useState } from "react";

import { formatCurrency } from "utils/currency";
import LenderLogo from "components/LenderLogo";

import { ReactComponent as ChevronUpIcon } from "assets/images/icons/chevron_up.svg";
import { ReactComponent as Logo } from "assets/images/logo.svg";

const LeftHandQuoteDetails = (props) => {
  const [ open, setOpen ] = useState(false);

  return (
    <div className={`left-hand-quote-details mobile-${open ? "open" : "closed"}`}>
      <div className="quote-details-logo">
        <Logo />
      </div>

      <div className="quote-details-title" onClick={e => setOpen(!open)}>
        Vehicle details

        <ChevronUpIcon />
      </div>

      <div className="quote-details-container">
        <div className="quote-details-vehicle">
          <div className="quote-details-image">
            <img src={props.application?.vehicle?.vehicleImage} />
          </div>
          <div className="quote-details-info">
            <span className="quote-details-vehicle-make">
              {props.application?.vehicle?.make}{" "}
              {props.application?.vehicle?.model}
            </span>
            <p>
              {props.application?.vehicle?.modelYear}{" "}
              {props.application?.vehicle?.fuel}{" "}
              {props.application?.vehicle.transmission}
            </p>
            <span className="quote-details-vehicle-price">
              Cash price:{" "}
              <span>
                {formatCurrency(props.application?.vehicle?.salesValue, 0)}
              </span>
            </span>
          </div>
        </div>

        <div className="quote-details-title">Finance details</div>

        <div className="quote-details-finance-info">
          <div className="quote-details-image">
            {props.lender?.lenderLogo && (
              <LenderLogo image={props.lender?.lenderLogo} />
            )}
          </div>
          <div className="quote-details-info">
            <span className="price-per-month">
              {formatCurrency(props.quote?.payments)}
            </span>
            <label className="price-per-month-label">per month</label>
          </div>
        </div>

        {!props.hideButtons && (
          <div className="quote-actions">
            <button className="dark-blue w-100" onClick={props.onView}>
              View full pre-approved quote
            </button>
            <button
              className="w-100 outline"
              onClick={props.onBack}
              style={{ color: "#8f8f8f" }}
            >
              Go back to offers
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

LeftHandQuoteDetails.defaultProps = {
  hideButtons: false,
};

export default LeftHandQuoteDetails;
