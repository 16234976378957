import generalTypes from "store/types/general";

export const setMetaData = (metadata) => ({
  type: generalTypes.SET_METADATA,
  payload: metadata,
})

export const setCurrentQuote = (quote) => ({
  type: generalTypes.SET_CURRENT_QUOTE,
  payload: quote,
});

export const clearCurrentQuote = () => ({
  type: generalTypes.SET_CURRENT_QUOTE,
  payload: {},
});
