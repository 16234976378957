import React, { useState } from "react";

import { formatCurrency } from "utils/currency";

import { ReactComponent as ChevronUpIcon } from "assets/images/icons/chevron_up.svg";

const VehicleInformation = (props) => {
  const [show, setShow] = useState(true);

  const shouldShow = () => {
    if (props.mobile) {
      return show;
    }

    return true;
  };

  const getClassNames = () => {
    let classes = [];

    if (props.mobile) {
      classes.push("flex lg:hidden flex-col mobile");

      if (show) {
        classes.push("mobile-open");
      } else {
        classes.push("mobile-closed");
      }
    } else {
      classes.push("hidden lg:flex flex-col");
    }

    return classes.join(" ");
  };

  return (
    <div
      className={`vehicle-information-container  ${getClassNames()}`}
      style={{ marginTop: 25 }}
    >
      {props.mobile ? (
        <div
          className="vehicle-information-header"
          onClick={(e) => setShow(!show)}
        >
          Vehicle information
          <ChevronUpIcon />
        </div>
      ) : null}

      {shouldShow() && (
        <div className="flex">
          <div className="vehicle-information-image">
            <img src={props.offer?.vehicle?.vehicleImage} />
          </div>

          <div className="vehicle-information-info-container">
            <div className="vehicle-information-make-model">
              {props.offer?.vehicle?.make} {props.offer?.vehicle?.model}
            </div>

            <div className="vehicle-information-info">
              {props.offer?.vehicle?.modelYear} {props.offer?.vehicle?.fuel}{" "}
              {props.offer?.vehicle.transmission}
            </div>

            <div className="vehicle-information-price">
              <label>Cash price:</label>
              <span>{formatCurrency(props.offer?.vehicle?.salesValue, 0)}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VehicleInformation;
