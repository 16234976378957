import React, { useEffect } from "react";

import Loader from "components/Loader";

const SuccessPage = (props) => {
  useEffect(() => {
    setTimeout(() => {
      props.onProceed();
    }, 1500);
  }, []);

  return (
    <div className="guest-page align-items-center loading-screen">
      <div className="guest-content retrieve-loading-screen full-height align-items-center lg:w-50 mt-4 w-100">
        <div className="guest-title">Fetching your offers</div>

        <Loader />

        <p className="text-center info-text">
          We’re matching you with lenders and preparing your offers, this could
          take up to 30 seconds
        </p>
      </div>
    </div>
  );
};

export default SuccessPage;
