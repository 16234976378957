import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import * as yup from "yup";

import { lookupPostcode } from "services";
import { getErrorLabels } from "utils/errors";
import { getOptionsArray } from "utils/options";
import Error from "components/Error";
import SelectInput from "components/input/SelectInput";

const Employment = (props) => {
  const defaultEmployerObject = {
    employerName: "",
    streetNumber: "",
    streetName: "",
    postcode: "",
    yearsAtJob: 0,
    monthsAtJob: 0,
  };

  const [ data, setData ] = useState({});
  const [ errors, setErrors ] = useState({});
  const [ previousEmployer, setPreviousEmployer ] = useState([defaultEmployerObject]);
  const [ showAddressFields, setShowAddressFields ] = useState(false);
  const [ showPreviousAddressFields, setShowPreviousAddressFields ] = useState([]);

  const metaData = useSelector(state => state?.general.metaData);

  const previousEmployerSchema = yup.object({
    employerName: yup.string().required(),
    employerAddress: yup.object({
      streetNumber: yup.string().required(),
      streetName: yup.string().required(),
      postcode: yup.string().required(),
    }),
    yearsAtJob: yup.number().min(0).required(),
    monthsAtJob: yup.number().min(0).max(11).required(),
  });

  const validationSchema = yup.object({
    occupied_status: yup.string().required(),
    employerAddress: yup.object({
      streetNumber: yup.string().required(),
      streetName: yup.string().required(),
      postcode: yup.string().required(),
    }),
    employerName: yup.string().required(),
    totalEmploymentMonths: yup.number().min(36).required(),
    employmentHistory: yup.array().of(previousEmployerSchema),
  });

  useEffect(() => {
    setData(props.data);

    if (props.data?.previousEmployment && props.data?.previousEmployment.length > 0) {
      let primaryMonths = parseInt(props?.data?.job_years * 12) + parseInt(props?.data?.job_months);
      let totalEmployment = getTotalEmploymentMonths(primaryMonths, props.data?.previousEmployment);

      if (totalEmployment < 36) {
        setPreviousEmployer([...props?.data?.previousEmployment, defaultEmployerObject]);
        setData({
          ...props.data,
          employmentHistory: convertEmploymentHistory([ ...props?.data?.previousEmployment, defaultEmployerObject]),
        });
      } else {
        setPreviousEmployer(props?.data?.previousEmployment);
      }

      let localShowPreviousAddressFields = [];
      props?.data?.previousEmployment?.map((employment, index) => {
        if (employment?.postcode) {
          localShowPreviousAddressFields.push(index);
        }
      });
      setShowPreviousAddressFields(localShowPreviousAddressFields);
    }

    if (props.data?.employerAddress?.postcode) {
      setShowAddressFields(true);
    }
  }, [props.data]);

  const handleChange = (e) => {
    let name = e.target.name;

    let newData = {};
    if (name.indexOf('.') >= 0) {
      let fieldName = name.split('.');

      newData = {
         ...data,
         employerAddress: {
           ...data.employerAddress,
           [fieldName[1]]: e.target.value,
         },
      }
    } else {
      newData = {
        ...data,
        [name]: e.target.value,
      };
    }

    if (getPrimaryEmploymentMonths() < 36) {
      newData.employmentHistory = convertEmploymentHistory(previousEmployer);
      newData.previousEmployment = previousEmployer;
    }

    setData(newData);
    props.onChange(newData);
  }

  const getPrimaryEmploymentMonths = () => {
    return (parseInt(data?.job_years) * 12) + parseInt(data?.job_months);
  } 

  const getTotalEmploymentMonths = (months = null, prevs = null) => {
    if (!months) {
      months = getPrimaryEmploymentMonths();
    }

    if (!prevs) {
      prevs = previousEmployer;
    }

    prevs.map(history => {
      months += (parseInt(history.yearsAtJob ? history.yearsAtJob : 0) * 12) + parseInt(history.monthsAtJob ? history.monthsAtJob : 0);
    });

    return months;
  }

  const handlePreviousEmployerChange = (index, e) => {
    let localPreviousEmployer = [ ...previousEmployer ];
    localPreviousEmployer = localPreviousEmployer.map((emp, i) => {
      if (i === index) {
        if (["monthsAtJob", "yearsAtJob"].includes(e.target.name)) {
          emp[e.target.name] = parseInt(e.target.value);
        } else {
          emp[e.target.name] = e.target.value;
        }
      }
      return emp;
    });
      
    if (["monthsAtJob", "yearsAtJob"].includes(e.target.name)) {
      let lastEmployer = localPreviousEmployer[localPreviousEmployer.length - 1];
      let isLastBlank = JSON.stringify(lastEmployer) === JSON.stringify(defaultEmployerObject);

      if (getTotalEmploymentMonths() < 36) {
        if (previousEmployer.length > 0) {
          if (!isLastBlank) {
            localPreviousEmployer.push(defaultEmployerObject);
          }
        }
      } else {
        if (isLastBlank) {
          localPreviousEmployer = localPreviousEmployer.filter(employer => JSON.stringify(employer) !== JSON.stringify(defaultEmployerObject));
        }
      }
    }

    setPreviousEmployer(localPreviousEmployer);

    let newData = { ...data };

    if (getPrimaryEmploymentMonths() < 36) {
      newData.employmentHistory = convertEmploymentHistory(localPreviousEmployer);
      newData.previousEmployment = localPreviousEmployer;
    }

    setData(newData);
    props.onChange(newData);
  }

  const convertEmploymentHistory = (records) => {
    return records.map(employer => {
      return {
        employerAddress: {
          streetNumber: employer.streetNumber,
          streetName: employer.streetName,
          postcode: employer.postcode,
        },
        employerName: employer.employerName,
        monthsAtJob: parseInt(employer.monthsAtJob),
        yearsAtJob: parseInt(employer.yearsAtJob),
      }
    });
  }

  const handlePostcodeLookup = (type = "current", index = null) => {
    let value;

    if (type === "current") {
      value = data.employerAddress.postcode;
    } else {
      value = previousEmployer[index].postcode;
    }

    if (value) {
      lookupPostcode(value)
        .then(res => {
            if (type === "current") {
              let newData = {
                ...data,
                employerAddress: {
                  ...data?.employerAddress,
                  streetName: res.street_name,
                },
              };

              setData(newData);
              props.onChange(newData);

              setShowAddressFields(true);
            } else {
              let localPreviousEmployers = [...previousEmployer];
              localPreviousEmployers[index].streetName = res.street_name;

              setPreviousEmployer(localPreviousEmployers);

              let localPreviousAddressFields = [...showPreviousAddressFields];
              localPreviousAddressFields.push(index);
              setShowPreviousAddressFields(localPreviousAddressFields);
            }

        });
    }
  }

  const handleOnProceed = () => {
    validationSchema.validate({
      ...data,
      totalEmploymentMonths: getTotalEmploymentMonths(),
    },
    {
      abortEarly: false
    })
      .then(() => {
        props.onProceed()
      })
      .catch(err => {
        setErrors(getErrorLabels(err));
      });
  }

  return (
    <div className="checkout-page">
      <div className="checkout-title">
        Employment
      </div>

      <div className="checkout-content full-height">
        <div className="form-container with-margin-bottom">
          <div className="form-item">
            <label className="dark">What industry do you work in?</label>
            <SelectInput
              options={getOptionsArray(metaData.occupationStatusList.sort())}
              value={data.occupied_status}
              name="occupied_status"
              onChange={handleChange}
              showBlank
            />

            <Error errors={errors} name="occupied_status" />
          </div>

          <div className="form-item">
            <label className="dark">How long have you been with your employer?</label>
            <div className="period-container">
              <div className="period-input">
                <label>Years</label>
                <input
                  name="job_years"
                  placeholder="YY"
                  type="number"
                  maxLength={2}
                  onChange={handleChange}
                  value={data?.job_years}
                />
              </div>
              <div className="period-input">
                <label>Months</label>
                <input
                  name="job_months"
                  placeholder="MM"
                  type="number"
                  maxLength={2}
                  onChange={handleChange}
                  value={data?.job_months}
                />
              </div>
            </div>
          </div>

          <div className="form-item">
            <label className="dark">What’s the name of your employer?</label>
            <input name="employerName" onChange={handleChange} value={props.data?.employerName} />
            <Error errors={errors} name="employerName" />
          </div>

          <div className="form-item">
            <label className="dark">What’s your employer's postcode?</label>
            <div className="d-flex justify-content: space-between align-items-center" style={{ gap: 20 }}>
              <input name="employerAddress.postcode" placeholder="Enter postcode" onChange={handleChange} value={data?.employerAddress?.postcode} />
              <button className="dark mt-0" onClick={e => handlePostcodeLookup('current')}>Lookup</button>
            </div>

            <Error errors={errors} name="employerAddress.postcode" />
          </div>

          { showAddressFields && (
            <Row>
              <Col xs={12} md={6}>
                <div className="form-item">
                  <label className="dark">Employer street number</label>
                  <input name="employerAddress.streetNumber" onChange={handleChange} value={data?.employerAddress?.streetNumber} />
                  <Error errors={errors} name="employerAddress.streetNumber" />
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="form-item">
                  <label className="dark">Employer street name</label>
                  <input name="employerAddress.streetName" onChange={handleChange} value={data?.employerAddress?.streetName} readOnly />
                  <Error errors={errors} name="employerAddress.streetName" />
                </div>
              </Col>
            </Row>
          )}

          { getPrimaryEmploymentMonths() < 36 && (
            <>
              { previousEmployer.map((employer, index) => (
                <div className="mt-3" key={index}>
                  <div className="checkout-title ps-0"> 
                    Previous Employment
                  </div>

                  <div className="form-item">
                    <label className="dark">How long were you with your employer?</label>
                    <div className="period-container">       
                      <div className="period-input">
                        <label>Years</label>
                        <input
                          name="yearsAtJob"
                          placeholder="YY"
                          type="number"
                          maxLength={2}
                          onChange={e => handlePreviousEmployerChange(index, e)}
                          value={employer?.yearsAtJob}
                        />
                      </div>
                      <div className="period-input">
                        <label>Months</label>
                        <input
                          name="monthsAtJob"
                          placeholder="MM"
                          type="number"
                          maxLength={2}
                          onChange={e => handlePreviousEmployerChange(index, e)}
                          value={employer?.monthsAtJob}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-item">
                    <label className="dark">What’s the name of your employer?</label>
                    <input name="employerName" onChange={e => handlePreviousEmployerChange(index, e)} value={employer.employerName} />
                    <Error errors={errors} name={`employmentHistory[${index}].employerName`} />
                  </div>

                  <div className="form-item">
                    <label className="dark">What’s your employer's address?</label>
                    <div className="d-flex justify-content: space-between align-items-center" style={{ gap: 20 }}>
                      <input name="postcode" placeholder="Enter postcode" onChange={e => handlePreviousEmployerChange(index, e)} value={employer?.postcode} />
                      <button className="dark mt-0" onClick={e => handlePostcodeLookup('previous', index)}>Lookup</button>
                    </div>
                    <Error errors={errors} name={`employmentHistory[${index}].employerAddress.postcode`} />
                  </div>

                  { showPreviousAddressFields.includes(index) && (
                    <Row>     
                      <Col xs={12} md={6}>
                        <div className="form-item">
                          <label className="dark">Employer street number</label>
                          <input name="streetNumber" onChange={e => handlePreviousEmployerChange(index, e)} value={employer?.streetNumber} />
                        </div>
                        <Error errors={errors} name={`employmentHistory[${index}].employerAddress.streetNumber`} />
                      </Col>    
                      <Col xs={12} md={6}>
                        <div className="form-item">
                          <label className="dark">Employer street name</label>
                          <input name="streetName" onChange={e => handlePreviousEmployerChange(index, e)} value={employer?.streetName} readOnly />
                        </div>
                        <Error errors={errors} name={`employmentHistory[${index}].employerAddress.streetName`} />
                      </Col>
                    </Row>  
                  )}
                </div>
              ))}
            </>
          )}

          <div className="form-item form-button">
            <button className="light-blue w-100" onClick={handleOnProceed}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Employment;
