import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getMetaData } from "services";
import { loginSuccessful } from "store/actions/auth";
import { setMetaData } from "store/actions/general";
import Loader from "components/Loader";

const LoadingPage = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    getMetaData().then((res) => {
      dispatch(setMetaData(res));
      dispatch(loginSuccessful(props.token));
    });
  }, []);

  return (
    <div className="guest-page-register middle center loading-screen">
      <div className="guest-content-register login-loading">
        <div className="guest-title">Fetching your details</div>
        <Loader size={100} />
      </div>
    </div>
  );
};

export default LoadingPage;
