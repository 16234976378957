import React from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as ApprovedIcon } from "assets/images/icons/green-tick.svg";

const Pending = (props) => {
  const navigate = useNavigate();

  return (
    <div className="decision-page">
      <div className="decision-content">
        <div className="decision-icon">
          <ApprovedIcon />
        </div>
        <div className="decision-title">
          Congratulations, the finance has been approved.
        </div>
        <div className="decision-text">
          The lender will now send the relevant documents and I.D. verification requirements directly to you.
        </div>
      </div>

      <div className="decision-action">
        <button className="green mt-auto" onClick={e => navigate('/offers')}>Finish</button>
      </div>
    </div>
  );
}

export default Pending;
