import React from "react";

const ComplianceQuestionPage = (props) => {
  const renderQuestion = () => {
    return (
      <>
        { props.question.split("\n").map((text, index) => (
          <p key={index}>{ text }</p>
        ))}
      </>
    )
  }

  return (
    <div className="application-checkout-questions fixed-button-section">
      { props.title && (
        <h4>{ props.title }</h4>
      )}

      <p>{ renderQuestion() }</p>

      <button onClick={props.onProceed} className="light-blue fixed-checkout-button" disabled={props.loading}>
        { props.loading ? "Submitting..." : (props.isLast ? "Apply" : "I understand") }
      </button>
    </div>
  );
}

export default ComplianceQuestionPage;
