import React from "react";
import { Dropdown } from "react-bootstrap";

const OfferFilter = (props) => {

  const handleOnChange = (e, value) => {
    e.preventDefault();
    props.onChange(value);
  }

  const getLabel = () => {
    switch (props.selected) {
      case 'all':
        return 'All';
      default:
        return props.selected;
    }
  }

  return (
    <>
      <div className="offer-filter">
        Show:

        <a
          href="#"
          onClick={e => handleOnChange(e, 'all')}
          className={props.selected === "all" ? "selected" : ""}
        >
          All
        </a>

        <a
          href="#"
          onClick={e => handleOnChange(e, 'HP')}
          className={props.selected === "HP" ? "selected" : ""}
        >
          HP/CS
        </a>

        <a
          href="#"
          onClick={e => handleOnChange(e, 'PCP')}
          className={props.selected === "PCP" ? "selected" : ""}
        >
          PCP
        </a>
      </div>

      <div className="offer-filter-mobile">
        <Dropdown onSelect={(val, e) => handleOnChange(e, val)}>
          <Dropdown.Toggle>
            Show: { getLabel() }
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item eventKey="all">All</Dropdown.Item>
            <Dropdown.Item eventKey="HP">HP</Dropdown.Item>
            <Dropdown.Item eventKey="PCP">PCP</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>        
      </div>
    </>
  );
}

OfferFilter.defaultProps = {
  selected: "all",
  onChange: () => {},
};

export default OfferFilter;
