import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { matchRoutes, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { ReactComponent as ChecklistIcon } from "assets/images/features/checklist.svg";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import { ReactComponent as RealtimeIcon } from "assets/images/features/realtime.svg";
import { ReactComponent as SimpleIcon } from "assets/images/features/simple.svg";
import { ReactComponent as TickIcon } from "assets/images/icons/tick.svg";

import { DealerContext, DealerMsgContext } from "components/DealerContext";
import { retrieveRoutes } from "routes";
import BenefitsList from "components/BenefitsList";
import FeaturesList from "components/FeaturesList";

const RetrieveLayout = (props) => {
  const user = useSelector((state) => state.auth.user);

  const location = useLocation();
  const result = matchRoutes(retrieveRoutes, location);
  const route = (result && result.length > 0 ? result[0]?.route : null);

  const [dealer, setDealer] = useState(null);
  const [showDealerMsg, setShowDealerMsg] = useState(true);

  return (
    <div className={`guest-layout page-${route ? route?.name.toLowerCase() : "home"} ${dealer ? "with-dealer" : ""}`}>
      <div className="page-logo">
        <Logo />
      </div>

      <div className="content-container flex-col">
        <div className="content">
          <Container fluid>
            <Row>
              <Col lg={6} className="left-container">
                <div className="logo-container">
                  <Logo className="content-logo" />
                </div>

                {user ? <BenefitsList /> : <FeaturesList showLogo={false} />}
              </Col>
              <Col xs={12} lg={6} className="right-container">
                <DealerContext.Provider value={setDealer}>
                  <DealerMsgContext.Provider value={setShowDealerMsg}>
                    {props.children}
                  </DealerMsgContext.Provider>
                </DealerContext.Provider>
              </Col>
            </Row>
          </Container>
        </div>

        {showDealerMsg && dealer && (
          <div className="dealer-info-banner">
            {dealer.name} login and checkout are powered by Fastcheck. By
            continuing, you agree to Fastcheck’s <a href="/">Terms of Use</a>{" "}
            and <a href="/">Privacy Policy</a>
          </div>
        )}
      </div>

      <footer className={`${dealer ? "dealer-footer" : ""}`}>

        {dealer ? (
          <>
            <div className="flex gap-5 partnership-logo">
              In partnership with:
              <img src={dealer.logo} />
            </div>
            <div className="company-name legal-info text-center">
              &copy; Fastcheck is a trading name of CrediCar Ltd, a company registered in England with company number 11328678
              and is authorised & regulated for Credit Brokering by the FCA under the number 938145
            </div>
          </>
        ) : (
          <div className="company-name">&copy; Fastcheck</div>
        )}

        <div className="links">
          <a href="https://fastcheck.co.uk/consumers/" className="about-link">
            About Fastcheck
          </a>
          <a href="https://fastcheck.co.uk/contact-us/">Contact</a>
          <a href="https://fastcheck.co.uk/privacy-policy/">Privacy & Terms</a>
        </div>
      </footer>
    </div>
  );
};

export default RetrieveLayout;
