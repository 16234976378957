import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { clearCurrentQuote } from "store/actions/general";
import { submittedApplications } from "services";
import { useDataLoading } from "components/loader/LoaderHook.jsx";
import Loader from "components/Loader";
import Offer from "components/offers/Offer";
import PageTitle from "components/misc/PageTitle";

const SubmittedApplications = () => {
  const dispatch = useDispatch();

  const { loading, setLoading } = useDataLoading(true);

  const [ applications, setApplications ] = useState([]);

  useEffect(() => {
    submittedApplications()
      .then(res => {
        setApplications(res.data);
        setLoading(false);
      });

    dispatch(clearCurrentQuote());
  }, []);

  return (
    <>
      {loading ? (
        <div className="dash-content flex items-center justify-center h-100">
          <Loader />
        </div>
      ) : (
        <>
          <PageTitle
            title="Submitted applications"
          />
          <div className="dash-content">
            <div className="offers-grid">
              {applications.map((application) => (
                <Offer key={application.id} offer={application} showStatus showCheckout={false} />
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SubmittedApplications;
