import React, { useEffect, useState } from "react";
import { format } from "date-fns";

import {
  getLenderQuestions,
  saveBankingDetails,
  setApplicationOccupation,
  getApplicationData
} from "services";
import { monthsToDuration } from "utils/general";
import Affordability from "components/forms/checkout/Affordability";
import AgreementEndInfoPage from "./AgreementEndInfoPage";
import AgreementInfoPage from "./AgreementInfoPage";
import ApplicationInfoPage from "./ApplicationInfoPage";
import Banking from "components/forms/checkout/Banking";
import ComplianceQuestionsPage from "./ComplianceQuestionsPage";
import CreditSearchInfoPage from "./CreditSearchInfoPage";
import EmploymentPage from "components/forms/checkout/Employment";
import FairWearInfoPage from "./FairWearInfoPage";
import InformationPage from "./InformationPage";
import LenderQuestionsPage from "./LenderQuestionsPage";
import MileageInfoPage from "./MileageInfoPage";
import ProgressTracker from "components/ProgressTracker";
import RepaymentsInfoPage from "./RepaymentsInfoPage";
import ReviewDetails from "./ReviewDetails";

import { ReactComponent as AffordabilityIcon } from "assets/images/icons/affordability.svg";
import { ReactComponent as BankingIcon } from "assets/images/icons/banking.svg";
import { ReactComponent as EmploymentIcon } from "assets/images/icons/employment.svg";

const ApplicationQuestions = (props) => {
  const [ data, setData ] = useState({});
  const [ questions, setQuestions ] = useState([]);
  const [ step, setStep ] = useState('info');

  useEffect(() => {
    document.getElementById('checkout-content-container')?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [step]);

  useEffect(() => {
    if (props.application) {
      getLenderQuestions(props.quote?.quoteGuid)
        .then(res => {
          setQuestions(res);
        });

      getApplicationData()
        .then(res => {
          let jobDuration = monthsToDuration(res.employment?.duration_months);
          let previousEmployment = {}

          if (res?.previousEmployment) {
            previousEmployment = {
              previousEmployment: [ ...res.previousEmployment.map(employment => {
                let jobDuration = monthsToDuration(employment?.duration_months);

                return {
                  employerName: employment?.employer_name,
                  streetNumber: employment?.employer_number,
                  streetName: employment?.employer_street_name,
                  postcode: employment?.employer_postcode,
                  yearsAtJob: parseInt(jobDuration?.years),
                  monthsAtJob: parseInt(jobDuration?.months),
                };
              }) ]
            }
          }

          setData({
            job_years: jobDuration?.years,
            job_months: jobDuration?.months,
            occupied_status: res.employment?.occupied_status ?? "",
            employerName: res?.employment?.employer_name ?? "",
            employerAddress: {
              streetNumber: res?.employment?.employer_number ?? "",
              streetName: res?.employment?.employer_street_name ?? "",
              postcode: res.employment?.employer_postcode ?? "",
            },
            nameOnAccount: res?.banking?.account_name ?? "",
            sortCode: res?.banking?.sort_code ?? "",
            bankAccountNumber: res?.banking?.account_number ?? "",
            curcumstancesChange: "",
            numberOfDependants: 0,
            affordCheck: "",
            replacementLoan: false,
            watchExplainerVideoDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            quoteDetailGuid: props.quote?.quoteGuid,
            ...previousEmployment,
          });
        });
    }
  }, []);

  const updateApplication = () => {
    let newData = {
      ...data,
      affordCheck: (data?.affordCheck ? format(new Date(), 'yyyy-MM-dd HH:mm:ss') : data?.affordCheck),
      employmentHistory: [
        ...data?.previousEmployment?.map(employer => {
          return {
            employerName: employer.employerName,
            employerAddress: {
              streetNumber: employer.streetNumber,
              streetName: employer.streetName,
              postcode: employer.postcode,
            },
            monthsAtJob: employer.monthsAtJob,
            yearsAtJob: employer.yearsAtJob,
          }
        }),
      ],
    };

    delete newData.job_years;
    delete newData.job_months;
    delete newData.previousEmployment;

    setApplicationOccupation(props.application?.applicationGuid, {
      occupationStatus: data.occupied_status,
      employment: {
        employerName: data?.employerName,
        ...data?.employerAddress,
      },
      employmentMonths: (parseInt(data?.job_years) * 12 + parseInt(data?.job_months)),
      employmentHistory: [
        ...data?.previousEmployment.map(employer => {
          return {
            employerName: employer.employerName,
            employerAddress: {
              streetNumber: employer.streetNumber,
              streetName: employer.streetName,
              postcode: employer.postcode,
            },
            monthsAtJob: employer.monthsAtJob,
            yearsAtJob: employer.yearsAtJob,
          }
        }),
      ],
    }).then(res => {
      saveBankingDetails(props.quote?.quoteGuid, newData)
        .then(res => {
          setStep('complianceQuestions');
        }).catch(res => {
          console.log('res', res);
        });
    }).catch(res => {
      console.log('res', res);
    });
  }

  const handleChange = (values) => {
    setData({
      ...data,
      ...values
    });
  }

  const handleProceed = () => {
    switch(step) {
      case 'info':
        setStep('employment');
        break;
      case 'employment':
        setStep('banking');
        break;
      case 'banking':
        setStep('affordability');
        break;
      case 'affordability':
        if (questions.length > 0) {
          setStep('lenderQuestions');
        } else {
          updateApplication();
        }
        break;
      case 'lenderQuestions':
        updateApplication();
        break;
      case 'complianceQuestions':
        props.onProceed();
        break;
    }
  }

  const commonProps = {
    quote: props.quote,
    application: props.application,
    onProceed: handleProceed,
  }

  const renderStep = () => {
    switch(step) {
      case 'info':
        return <InformationPage {...commonProps} />
      case 'employment':
        return <EmploymentPage {...commonProps} data={data} onChange={handleChange} />
      case 'banking':
        return <Banking {...commonProps} data={data} onChange={handleChange} />
      case 'affordability':
        return <Affordability {...commonProps} data={data} onChange={handleChange} />
      case 'lenderQuestions':
        return <LenderQuestionsPage {...commonProps} data={data} onChange={handleChange} questions={questions} /> 
      case 'complianceQuestions':
        return <ComplianceQuestionsPage onProceed={handleProceed} questions={props.questions} />
    }
  }

  return (
    <>
      { ['employment', 'banking', 'affordability'].includes(step) && (
        <ProgressTracker
          steps={[
            {
              value: "employment",
              label: "Employment",
              icon: <EmploymentIcon height={26} className="stroke" />,
              completed: true,
            },
            {
              value: "banking",
              label: "Banking",
              icon: <BankingIcon  height={26} className="fill" />,
              completed: ['affordability', 'banking'].includes(step),
            },
            {
              value: "affordability",
              label: "Affordability",
              icon: <AffordabilityIcon height={26} className="stroke" />,
              completed: step === "affordability",
            },
          ]}
          onClick={step => setStep(step.value)}
        />
      )}
      { renderStep() }
    </>
  );
}

export default ApplicationQuestions;
