import React from "react";

import { ReactComponent as ChecklistIcon } from "assets/images/features/checklist.svg";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import { ReactComponent as RealtimeIcon } from "assets/images/features/realtime.svg";
import { ReactComponent as SimpleIcon } from "assets/images/features/simple.svg";

const FeaturesList = (props) => {
  return (
    <>
      {props.showLogo && (
        <div className="logo-container">
          <Logo className="content-logo" />
        </div>
      )}
      <div className="feature">
        <div className="feature-icon-title">
          <div className="feature-icon">
            <ChecklistIcon />
          </div>
          <div className="feature-title">
            One application, multiple loan offers
          </div>
        </div>
        <div className="feature-text">
          Multiple Loan offers: We bring you a variety of pre-approved loans,
          allowing you to compare and choose the one that fits just right.
        </div>
      </div>

      <div className="feature">
        <div className="feature-icon-title">
          <div className="feature-icon">
            <RealtimeIcon />
          </div>
          <div className="feature-title">Real-time pre-approvals</div>
        </div>
        <div className="feature-text">
          Get your loan pre-approved in a blink. With Fastcheck, that's exactly
          what you get. No more endless waits wondering if you're eligible.
        </div>
      </div>

      <div className="feature">
        <div className="feature-icon-title">
          <div className="feature-icon">
            <SimpleIcon />
          </div>
          <div className="feature-title">A fast & simple process</div>
        </div>
        <div className="feature-text">
          Say goodbye to the mountain of paperwork and the back-and-forth with
          lenders. We've cut down the hassle, making your journey to owning a
          car a fast and simple process.
        </div>
      </div>
    </>
  );
};

FeaturesList.defaultProps = {
  showLogo: true,
};

export default FeaturesList;
