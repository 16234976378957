import React, { useEffect, useState } from "react";

import Error from "components/Error";

const LenderQuestionsPage = (props) => {
  const [ answers, setAnswers ] = useState({});
  const [ errors, setErrors ] = useState({});

  useEffect(() => {
    if (props.questions.length === 0) {
      props.onProceed();
    } else {
      console.log('props.questions', props.questions);
    }
  }, []);

  const formatLenderQuestions = () => {
    let lenderQs = [];
    props.questions.map(question => {
      let key = question.questionGuid;
      let value  = answers[key];

      lenderQs.push({
        questionGuid: key,
        answerValue: value,
      });
    });

    return lenderQs;
  }

  useEffect(() => {
    let questionsFormatted = formatLenderQuestions();
    props.onChange({
      lenderQuestions: questionsFormatted,
    });
  }, [answers]);

  const handleChange = (e) => {
    setAnswers({
      [e.target.name]: e.target.value,
    });
  }

  return (
    <div className="application-checkout-questions">
      <h4>The lender has the following questions</h4>

      <div className="form-container">
        { props.questions?.map(question => (
          <div className="form-item">
            <label className="dark">{ question.lenderSpecificQuestion }</label>
            <input type="text" name={ question.questionGuid } onChange={handleChange} />

            <Error errors={errors} name={ question.questionGuid } />
          </div>
        ))}
      </div>

      <button onClick={props.onProceed} className="light-blue">
        Continue
      </button>
    </div>
  );
}

LenderQuestionsPage.defaultProps = {
  questions: [],
}

export default LenderQuestionsPage;
