import React, { useEffect, useState } from "react";

import Loader from "components/Loader";

const CheckingPage = (props) => {
  const [ token, setToken ] = useState(null);
  const [ userFound, setUserFound ] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setUserFound(props.token !== null);

      setTimeout(() => {
        if (props.token) {
          props.onLogin();
        } else {
          props.onRegister();
        }
      }, 1500);
    }, 2000);    
  }, []);

  return (
    <div className="guest-page align-items-center">
      <div className="guest-content full-height align-items-center w-50 mt-4 retrieve-loading-screen">
        <div className="guest-title">
          Fetching your details 
        </div>

        <Loader />

          <p className="text-center info-text">
            { userFound === true ? (
              <>We've located your details, we'll redirect you to your account.</>
            ) : userFound === false ?  (
              <>It looks like we don’t have any details for you, so let’s get you registered.</>
            ) : (
              <>Please wait, we are currently checking your details.</>
            )}
          </p>
      </div>
    </div>
  );
}

export default CheckingPage;
