import React from "react";

const SelectInput = (props) => {
  return (
    <select name={props.name} onChange={props.onChange} value={props.value}>
      { props.showBlank && (
        <option value={null}>Please select...</option>
      )}

      { props.options.map(option => (
        <option key={option.value} value={option.value}>{ option.label }</option>
      ))}
    </select>
  )
}

SelectInput.defaultProps = {
  name: "",
  onChange: () => {},
  options: [],
  showBlank: false,
  value: null,
}

export default SelectInput;
