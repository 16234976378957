import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { parse, format } from "date-fns";

import { getProfile, updateProfile } from "services";
import { useDataLoading } from "components/loader/LoaderHook";
import LeftHandQuoteDetails from "components/LeftHandQuoteDetails";
import DrivingInfo from "components/forms/profile/DrivingInfo";
import MyProfileForm from "components/forms/MyProfileForm";
import Occupation from "components/forms/profile/Occupation";
import PersonalInfo from "components/forms/profile/PersonalInfo";

const ReviewDetails = (props) => {
  const [compareData, setCompareData] = useState({});
  const [data, setData] = useState({});
  const [section, setSection] = useState("profile");

  useEffect(() => {
    commitData(props.data);
  }, []);

  const commitData = (info) => {
    let dob = parse(info?.dob, "yyyy-MM-dd", new Date());

    setData({
      ...info,
      dob_day: format(dob, "dd"),
      dob_month: format(dob, "MM"),
      dob_year: format(dob, "yyyy"),
    });

    setCompareData({
      ...info,
    });
  };

  const updateUserData = (newData) => {
    const dataObj = {
      ...data,
      ...newData,
      applicationGuid: props.application,
    };

    updateProfile(dataObj)
      .then((res) => {
        commitData(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });

    setData(dataObj);
    setCompareData({
      ...compareData,
      ...newData,
    });
  };

  const commonProps = {
    data,
    onUpdate: (data) => updateUserData(data),
    onProceed: () => setSection("profile"),
    profile: true,
  };

  const renderSection = () => {
    switch (section) {
      case "profile":
        return (
          <MyProfileForm
            data={data}
            setStep={(section) => setSection(section)}
          />
        );
      case "driving":
        return <DrivingInfo {...commonProps} />;
      case "occupation":
        return <Occupation {...commonProps} />;
      case "personal":
        return <PersonalInfo {...commonProps} />;
    }
  };

  return (
    <>
      <div className="page-title">Review details</div>
      <div className="page-subtext mb-4">
        Let’s quickly review your details to make sure we’re all good and then
        we’ll get to your offers (after a few legal requirements!)
      </div>

      <div className="page-content full-height">
        {renderSection()}

        {section === "profile" && (
          <div className="review-details-actions">
            <button
              className="green-2"
              onClick={(e) => props.onProceed(compareData)}
            >
              Checkout & Apply
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ReviewDetails;
