import React, { useEffect, useState } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { requestPx } from "services";
import { formatCurrency } from "utils/currency";
import { ReactComponent as InfoIcon } from "assets/images/icons/info.svg";
import HPModal from "components/modals/HPModal";
import LenderLogo from "components/LenderLogo";
import PCPModal from "components/modals/PCPModal";

const Quote = (props) => {
  const navigate = useNavigate();

  const [ showHPModal, setShowHPModal ] = useState(false);
  const [ showPCPModal, setShowPCPModal ] = useState(false);

  useEffect(() => {
    if (props.pxRequest) {
      setPxRequest(props.pxRequest);
    }
  }, [props.pxRequest]);

  const [pxRequest, setPxRequest] = useState({
    pending: false,
    requested: false,
  });

  const canShowSubmitToLender = () => {
    if (pxRequest.pending) {
      return false;
    }

    const anyQuotesAccepted = props.application.lenderList.some((lender) => {
      return lender.quoteList.some((quote) => {
        return ["approved", "pending", "referred"].includes(quote.status);
      });
    });

    if (anyQuotesAccepted) {
      return false;
    }

    if (props.quote.status === "Declined") {
      return false;
    }

    return true;
  };

  const handleRequestPx = () => {
    requestPx(props?.application?.applicationGuid, { email: props.application?.dealer?.email }).then((res) => {
      setPxRequest(res);

      props.onPxRequest(res);
    });
  };

  const onProductClick = () => {
    if (props.quote.productType === "HP") {
      setShowHPModal(true);
    } else if (props.quote.productType === "pcp") {
      setShowPCPModal(true);
    }                     
  }

  return (
    <div className="offers-card">
      <div className="offers-vehicle">
        <div className="vehicle-image">
          <LenderLogo image={props.lender.lenderLogo} />
        </div>
        <div className="offers-ppm-smaller">
          <h4>{formatCurrency(props.quote.payments)}</h4> p/m
        </div>
      </div>
      <div className="offers-finance">
        <div className="offers-finance-details">
          <div className="finance-details-tooltip">
            <h4>Total payable</h4>
            <OverlayTrigger
              placement="top-start"
              delay={{ hide: 400, show: 300 }}
              overlay={<Tooltip>This is the total that you will pay to purchase your vehicle using this finance agreement. It includes your deposit and all scheduled monthly payments</Tooltip>}
            >
              <InfoIcon />
            </OverlayTrigger>{" "}
          </div>

          {formatCurrency(props.quote.totalPayable)}
        </div>
        <div className="offers-finance-details">
          <div className="finance-details-tooltip">
            <h4>APR</h4>
            <OverlayTrigger
              placement="top-start"
              delay={{ hide: 400, show: 300 }}
              overlay={<Tooltip>This APR represents the interest rate applied to your agreement</Tooltip>}
            >
              <InfoIcon />
            </OverlayTrigger>
          </div>
          {props.quote.apr.toFixed(1)}%
        </div>
        <div className="offers-finance-details">
          <div className="finance-details-tooltip">
            <h4>Final payment</h4>
            <OverlayTrigger
              placement="top-start"
              delay={{ hide: 400, show: 300 }}
              overlay={<Tooltip>The last monthly payment that you will pay towards your vehicle</Tooltip>}
            >
              <InfoIcon />
            </OverlayTrigger>{" "}
          </div>

          {formatCurrency(props.quote.residualValue)}
        </div>
        <div className="offers-finance-details">
          <div className="finance-details-tooltip">
            <h4>Lender </h4>
            <OverlayTrigger
              placement="top-start"
              delay={{ hide: 400, show: 300 }}
              overlay={<Tooltip>This is the lender that will provide your finance</Tooltip>}
            >
              <InfoIcon />
            </OverlayTrigger>
          </div>
          {props.lender.lenderName}
        </div>
        <div className="offers-finance-details">
          <div className="finance-details-tooltip">
            <h4>Finance type</h4>
            <InfoIcon onClick={onProductClick} />
          </div>
          {props.quote.productTypeDesc}
        </div>
        <div className="offers-finance-details">
          <div className="finance-details-tooltip">
            <h4>Total interest cost</h4>
            <OverlayTrigger
              placement="top-start"
              delay={{ hide: 400, show: 300 }}
              overlay={<Tooltip>The total interest charges payable under the normal running of the finance agreement</Tooltip>}
            >
              <InfoIcon />
            </OverlayTrigger>{" "}
          </div>

          {formatCurrency(props.quote.totalInterest)}
        </div>
      </div>
      <div className="offers-smaller-screen">
        <div className="offers-ppm">
          <h4>{formatCurrency(props.quote.payments)}</h4> p/m
        </div>
        <div className="offers-buttons">
          <button
            onClick={(e) => props.onSelect(props.quote, props.lender)}
            className="full-quote-button"
          >
            View full quote
          </button>
          {canShowSubmitToLender() && (
            <button
              onClick={(e) =>
                navigate(
                  `/offers/${props.application.applicationGuid}/checkout/${props.quote.quoteGuid}`
                )
              }
              className="checkout-button"
            >
              Checkout & Apply
            </button>
          )}
        </div>
      </div>

      <HPModal show={showHPModal} onHide={e => setShowHPModal(false)} />
      <PCPModal show={showPCPModal} onHide={e => setShowPCPModal(false)} />
    </div>
  );
};

export default Quote;
