import React from "react";
import { useNavigate } from "react-router-dom";

import FeatureList from "components/FeaturesList";

const DashboardPage = (props) => {
  const navigate = useNavigate();

  return (
    <div className="dashboard-content">
      <div className="left-hand-content">
        <FeatureList />

        <div className="d-lg-none d-block w-100">
          <button
            onClick={() => navigate('/offers')}
            className="dark-blue full rounded"
          >
            View my offers
          </button>
        </div>
      </div>
      <div className="right-hand-content" />
    </div>
  );
};

export default DashboardPage;
