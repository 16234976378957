import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import _ from "lodash";

import { applicationToProfile } from "utils/general";
import { clearCurrentQuote } from "store/actions/general";
import { selectQuote, submitApplication } from "services";
import { useDataLoading } from "components/loader/LoaderHook";
import ApplicationQuestions from "pages/auth/offers/checkout";
import DecisionPage from "pages/auth/offers/checkout/decision";
import FullQuoteInfo from "components/offers/FullQuoteInfo";
import LeftHandQuoteDetails from "components/LeftHandQuoteDetails";
import Loader from "components/Loader";
import ReviewDetails from "pages/auth/offers/checkout/ReviewDetails";

const QuoteCheckoutPage = (props) => {
  const { offer, id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, setLoading } = useDataLoading(true);

  const [ application, setApplication ] = useState(null);
  const [ lender, setLender ] = useState(null);
  const [ profileData, setProfileData ] = useState(null);
  const [ questions, setQuestions ] = useState(null);
  const [ quote, setQuote ] = useState(null);
  const [ result, setResult ] = useState(null);
  const [ section, setSection ] = useState("review");

  useEffect(() => {
    selectQuote(id)
      .then(res => {
        setApplication(res.quote);
        setQuote(res.quote.quote);
        setLender(res.quote.lender);
        setQuestions(res.questions);

        setProfileData(applicationToProfile(res.quote));
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    document.getElementById('checkout-content-container')?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [section]);

  const handleSubmitApplication = () => {
    submitApplication(quote?.quoteGuid, {
      creditSearchDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    }).then(res => {
      if (res?.applicationStatus == "ProposalSubmitted") {
        setResult("pending");
      } else if (res?.applicationStatus === "LenderDecicionRecived") {
        setResult(res?.quote?.status?.toLowerCase());
      }

      dispatch(clearCurrentQuote());
      setSection('result');
    }).catch(err => {
      console.log('err', err);
    });
  };

  const handleReviewDetailsProceed = (values) => {
    if (_.isEqual(values, profileData)) {
      setSection('questionInfo');
    } else {
      let profileAddress = _.pick(profileData, ['door_number', 'street_name', 'city', 'postcode']);
      let valueAddress = _.pick(values, ['door_number', 'street_name', 'city', 'postcode']);

      if (_.isEqual(profileAddress, valueAddress)) {
        setSection('questionInfo');
      } else {
        navigate(`/offers/${offer}/confirm`);
      }
    }
  }

  const renderSection = () => {
    switch(section) {
      case "review":
        return <ReviewDetails data={profileData} onProceed={handleReviewDetailsProceed} application={offer} />
      case "view":
        return <FullQuoteInfo quote={quote} application={application} lender={lender} embedded onClose={e => setSection('review')} showCheckout={false} />;
      case "questionInfo":
        return <ApplicationQuestions application={application} quote={quote} lender={lender} onProceed={handleSubmitApplication} questions={questions} />
      case "result":
        return <DecisionPage application={application} quote={quote} lender={lender} result={result} />
    }
  }

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center w-100 loading-screen h-100">
          <Loader />
        </div>
      ) : (
        <Container fluid className="quote-checkout-page">
          <Row>
            <Col xs={12} lg={6} className="left-hand-content">
              <div className="d-flex flex-column justify-content-evenly">
                <LeftHandQuoteDetails
                  application={application}
                  quote={quote}
                  lender={lender}
                  onBack={(e) => navigate(`/offers/${offer}`)}
                  onView={(e) => setSection("view")}
                  hideButtons={section === "result"}
                />
              </div>
            </Col>
            <Col xs={12} lg={6} className="right-hand-content">
              {renderSection()}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default QuoteCheckoutPage;
