import React from "react";
import { Modal } from "react-bootstrap";

const HPModal = (props) => {
  if (!props.show) {
    return null;
  }

  return (
    <Modal
      centered
      show={props.show}
      onHide={props.onHide}
      className="product-info-modal blurred"
    >
      <Modal.Header closeButton>
        HP Finance Explained
      </Modal.Header>
      <Modal.Body>
        <h3 className="info-title">What is HP?</h3>

        <p>
          Hire Purchase (HP) is type of finance that allows customers to buy a car
          outright without having to pay the whole amount upfront and with no
          large final payment. The cost is split across a deposit and a series of
          fixed monthly instalments, typically spread over two to five years. The
          customer will automatically own the vehicle as soon as they have made
          the final instalment.
        </p>

        <h3 className="info-title">How Hire Purchase works</h3>

	<p>* You set an initial deposit, agreement term and the monthly repayments are automatically calculated by the Fastcheck platform.</p>
        <p>* When you are happy with the term and payments, you will pay a deposit directly to the dealer from the price of the car.</p>
        <p>* You then enter into the agreement with the lender and pays the remaining balance plus interest in equal monthly repayments over the agreed term.</p>
        <p>* In a HP agreement the lender buys the car on your behalf and owns the vehicle for the duration of the finance agreement.</p>
        <p>* After you have made all the repayments including any interest, you will become the owner of the vehicle.</p>
      </Modal.Body>
    </Modal>
  )
}

export default HPModal;
