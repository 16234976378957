import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { parseISO, format } from "date-fns";

import { getNotifications, markNotificationsAsRead } from "services";
import PageTitle from "components/misc/PageTitle";

const Notifications = () => {
  const [ notifications, setNotifications ] = useState([]);

  useEffect(() => {
    getNotifications()
      .then(res => {
        setNotifications(res?.data);
        markNotificationsAsRead();
      });
  }, []);

  return (
    <>
      <PageTitle
        title="Notifications"
      />

      { notifications.length > 0 && (
        <div className="notifications-container">
          { notifications.map(notification => (
            <div className="notification-item-container">
              <Link
                to={notification.link}
                className="notification-content"
              >
                <div className="notification-text">
                  { notification.notification }
                </div>
                <div className="notification-time">
                  { format(parseISO(notification.created), 'dd/MM/yyyy HH:mm') }
                </div>
              </Link>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Notifications;
