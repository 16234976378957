import React from "react";
import { Link } from "react-router-dom";
import { parseISO, format } from "date-fns";

const RetailerCard = ({ retailer }) => {
  const { logo, offersCount, lastQuoteDate, vehicle, price } = retailer;

  return (
    <div className="retailer-card">
      <img src={logo} alt="Retailer Logo" className="retailer-logo" />
      <div className="retailer-details">
        <div className="offers">
          <p>Offers with retailer:</p> <h4>{offersCount}</h4>
        </div>
        <div className="last-quote">
          <p>Last quote with retailer:</p>
        </div>
        <div className="vehicle-details">
          <h5>
            { lastQuoteDate ? (
              <>{format(parseISO(lastQuoteDate), "dd/MM/yyyy")}</>
            ) : (
              <>N/A</>
            )}
          </h5>
          <p>
            { vehicle ? (
              <>{vehicle} - £{price.toLocaleString('en-GB')}</>
            ) : (
              <>N/A</>
            )}
          </p>
        </div>
      </div>
      <Link key={retailer.id} to={`/retailers/${retailer.id}/offers`}>
        <button className="view-offers-button" disabled={offersCount === 0}>View retailers offers</button>
      </Link>
    </div>
  );
};

export default RetailerCard;
