import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as DrivingIcon } from "assets/images/icons/driving-icon.svg";
import { ReactComponent as OccupationIcon } from "assets/images/icons/occupation-icon.svg";
import { ReactComponent as PersonalIcon } from "assets/images/icons/personal-icon.svg";

import { convertQuote, getSentQuote, getDealerFromGuid } from "services";
import { loginSuccessful, setUser } from "store/actions/auth";
import { setCurrentQuote } from "store/actions/general";
import { DealerContext, DealerMsgContext } from "components/DealerContext";
import CheckingPage from "./CheckingPage";
import ConfirmationCodePage from "pages/guest/register/ConfirmationCodePage";
import DrivingInfoPage from "pages/guest/register/DrivingInfoPage";
import InvalidTokenPage from "pages/retrieve/InvalidTokenPage";
import LegalStuffPage from "pages/guest/register/LegalStuffPage";
import OccupationPage from "pages/guest/register/OccupationPage";
import PersonalInfoPage from "pages/guest/register/PersonalInfoPage";
import ProgressTracker from "components/ProgressTracker";
import ReviewDetailsPage from "pages/guest/register/ReviewDetailsPage";
import SuccessPage from "./SuccessPage";
import RetrieveQuoteForm from "components/forms/RetrieveQuoteForm";

const RetrieveQuotePage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { quote, token } = useParams();

  const authToken = useSelector(state => state?.auth?.token);

  const [ userData, setUserData ] = useState({});
  const [ step, setStep ] = useState("telephone");
  const [ userToken, setUserToken ] = useState(null);
  const [ currentDealer, setCurrentDealer ] = useState(null);

  const setDealer = useContext(DealerContext);
  const setShowDealerMsg = useContext(DealerMsgContext);

  useEffect(() => {
    if (authToken) {
      setStep('legals');
    }

    if (quote || token) {
      getDealerFromGuid({
        quote,
        token,
      }).then(res => {
        setDealer(res?.data);
        setCurrentDealer(res?.data);
      });
    }
  }, []);

  const updateUserData = (data) => {
    setUserData({
      ...userData,
      ...data,
    });
  }

  const handleRetrieveUpdate = (data) => {
    updateUserData(data);
    setStep("confirmation");
    setShowDealerMsg(false);
  }

  const handleConfirmCode = (data) => {
    if (token) {
      dispatch(setUser(data.user));
      dispatch(loginSuccessful(data.token));
    }

    setUserToken(data);
    setStep('checking');
  };

  const handleOnFinish = () => {
    if (token) {
      getSentQuote(token)
        .then(res => {
          navigate(`/offers/${res.application}/checkout/${res.quote}`);
        }).catch(res => {
          setStep('invalidToken');
        });
    } else {
      convertQuote(quote)
        .then(res => {
          if (res.quote) {
            dispatch(setCurrentQuote(res.quote));

            setTimeout(() => {
              navigate(`/offers/${res.quote.applicationGuid}`);
            }, 500);
          }
        });
    }
  }

  const handleOnLogin = () => {
    if (Object.keys(userToken).includes('user')) {
      dispatch(loginSuccessful(userToken.token));
      dispatch(setUser(userToken.user));
    } else {
      dispatch(loginSuccessful(userToken));
    }

    setStep("legals");
  }

  const handleOnLegalConfirm = (data) => {
    if (!authToken) {
      setUserToken(data);

      dispatch(setUser(data.user));
      dispatch(loginSuccessful(data.token));
    }

    setStep('success')
  };

  const handleOnVerify = (payload) => {
    setStep("checking");
    setUserToken(payload);
  }

  return (
    <>
      { ['driving', 'occupation', 'personal'].includes(step) && (
        <ProgressTracker
          steps={[
            {
              value: "driving",
              label: "Driving information",
              icon: <DrivingIcon height={26} className="stroke" />,
              completed: true,
            },
            {
              value: "occupation",
              label: "Occupation",
              icon: <OccupationIcon  height={26} className="fill" />,
              completed: ['occupation', 'personal'].includes(step),
            },
            {
              value: "personal",
              label: "Personal information",
              icon: <PersonalIcon height={26} className="stroke" />,
              completed: step === "personal",
            },
          ]}
          onClick={step => setStep(step.value)}
        />
      )}

      { step === "telephone" ? (
        <RetrieveQuoteForm
          onProceed={handleRetrieveUpdate}
          onPasskeyLogin={handleOnVerify}
        />
      ) : step === "confirmation" ? (
        <ConfirmationCodePage
          data={userData}
          text={`Please enter the 6 digit code we<br/>have sent to your ${userData?.email ? "email inbox" : "phone"}`}
          textAlign="center"
          showLogin={false}
          type="retrieve"
          onProceed={handleConfirmCode}
        />
      ) : step === "checking" ? (
        <CheckingPage
          token={userToken}
          onRegister={e => setStep("driving")}
          onLogin={handleOnLogin}
        />
      ) : step === "driving" ? (
        <DrivingInfoPage
          data={userData}
          onProceed={e => setStep("occupation")}
          onUpdate={updateUserData}
        />
      ) : step === "occupation" ? (
        <OccupationPage
          data={userData}
          onProceed={e => setStep("personal")}
          onUpdate={updateUserData}
        />
      ) : step === "personal" ? (
        <PersonalInfoPage
          data={userData}
          onProceed={e => setStep("review")}
          onUpdate={updateUserData}
        />
      ) : step === "review" ? (
        <ReviewDetailsPage
          data={userData}
          onProceed={e => setStep("legals")}
          setStep={setStep}
        />
      ) : step === "legals" ? (
        <LegalStuffPage
          onProceed={handleOnLegalConfirm}
          dealer={currentDealer}
        />
      ) : step === "success" ? (
        <SuccessPage onProceed={handleOnFinish} />
      ) : step === "invalidToken" ? (
        <InvalidTokenPage />
      ) : (
        <></>
      )}
    </>
  );
};

export default RetrieveQuotePage;
