import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PinInput from "react-pin-input";

import { checkVerificationCode, sendVerificationCode } from "services";

const ConfirmationCodePage = (props) => {
  const [codeSent, setCodeSent] = useState(false);
  const [pinCode, setPinCode] = useState(null);
  const [showCodeError, setShowCodeError] = useState(false);

  const navigate = useNavigate();

  const handleResend = (e) => {
    e.preventDefault();

    const method = props?.data?.phone ? "phone" : "email";

    sendVerificationCode({ [method]: props.data?.phone ?? props.data?.email, type: "register" })
      .then((res) => {
        setCodeSent(true);

        setTimeout(() => {
          setCodeSent(false);
        }, 2000);
      })
      .catch((err) => {});
  };

  const handleProceed = () => {
    checkVerificationCode({ ...props.data, code: pinCode, type: props.type })
      .then((res) => {
        if (props.type === "retrieve") {
          if (res.user) {
            props.onProceed(res);
          } else {
            props.onProceed(null);
          }
        } else {
          props.onProceed();
        }
      })
      .catch((res) => {
        setShowCodeError(true);
      });
  };

  return (
    <div className="guest-page-register">
      <div className="guest-content-register">
        <div
          className={`guest-title-register text-${props.textAlign}`}
          dangerouslySetInnerHTML={{ __html: props.text }}
        />

        <div className="form-container">
          <div className="form-item">
            <PinInput
              length={6}
              focus
              secret
              secretDelay={250}
              type="numeric"
              inputMode="numeric"
              autoSelect
              onComplete={(value) => setPinCode(value)}
            />

            {showCodeError && (
              <div className="form-error text-center">
                Invalid code, please try again or click below to send another
                code
              </div>
            )}
          </div>

          <div className="form-item">
            <button className="light-blue full" onClick={handleProceed}>
              Confirm
            </button>
          </div>

          <div className="form-info">
            {codeSent ? (
              <a className="text-green-600 success">Code successfully sent</a>
            ) : (
              <>
                <span>Didn’t receive the code?</span>
                <a href="#" onClick={handleResend}>
                  Send again
                </a>
              </>
            )}
          </div>
        </div>
      </div>

      {props.showLogin && (
        <div className="bottom-content-register">
          Already registered with Fastcheck?
          <a onClick={(e) => navigate("/")}>Login here</a>
        </div>
      )}
    </div>
  );
};

ConfirmationCodePage.defaultProps = {
  text: "Enter the confirmation code",
  textAlign: "left",
  showLogin: true,
  type: "register",
};

export default ConfirmationCodePage;
