import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, matchRoutes, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

import { logout } from "store/actions/auth";
import { authRoutes } from "routes";

import { ReactComponent as BackIcon } from "assets/images/icons/back_icon.svg";
import { ReactComponent as Logo } from "assets/images/logo.svg";

const UserInfo = React.forwardRef(({ children, onClick, user }, ref) => (
  <div className="page-box" onClick={onClick}>
    <div>
      {user.first_name} {user.last_name}
    </div>
  </div>
));

const CheckoutLayout = (props) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  const location = useLocation();
  const result = matchRoutes(authRoutes, location);
  const route = (result && result.length > 0 ? result[0]?.route : null);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className={`checkout-layout page-${route ? route?.name.toLowerCase() : ""}`}>
      <div className="checkout-topbar">
        <div className="page-logo">
          <Logo />
        </div>
        <div className="checkout-topbar-buttons">
          <div className="back-link">
            <Link to="/">Go back to my profile</Link>
          </div>

          <Dropdown className="user-menu-dropdown">
            <Dropdown.Toggle
              as={({ onClick }) => {
                return <UserInfo user={user} onClick={onClick} />;
              }}
              id="user-menu"
            />

            <Dropdown.Menu>
              <Dropdown.Item eventKey="logout" onClick={handleLogout}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className="content-container" id="checkout-content-container">
        <div className="content">{props.children}</div>
      </div>

      <footer>
        <div className="company-name">&copy; Fastcheck</div>
        <div className="links">
          <a href="https://fastcheck.co.uk/consumers/" className="about-link">
            About Fastcheck
          </a>
          <a href="https://fastcheck.co.uk/contact-us/">Contact</a>
          <a href="https://fastcheck.co.uk/privacy-policy/">Privacy & Terms</a>
        </div>
      </footer>
    </div>
  );
};

export default CheckoutLayout;
