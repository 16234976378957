import axios from "./base";

export const getMetaData = () => {
  return axios.get('/journey/meta').then(res => res.data);
}

export const lookupPostcode = (postcode) => {
  return axios.post('/journey/postcode', { postcode }).then(res => res.data);
}

export const getPolicies = (params) => {
  return axios.get('/journey/policies', { params }).then(res => res.data);
}
