import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ReactComponent as DrivingIcon } from "assets/images/icons/driving-icon.svg";
import { ReactComponent as OccupationIcon } from "assets/images/icons/occupation-icon.svg";
import { ReactComponent as PersonalIcon } from "assets/images/icons/personal-icon.svg";

import { setLoginData } from "store/actions/auth";
import ConfirmationCodePage from "./ConfirmationCodePage";
import DrivingInfoPage from "./DrivingInfoPage";
import LegalStuffPage from "./LegalStuffPage";
import OccupationPage from "./OccupationPage";
import PersonalInfoPage from "./PersonalInfoPage";
import ProgressTracker from "components/ProgressTracker";
import ReviewDetailsPage from "./ReviewDetailsPage";
import SuccessPage from "./SuccessPage";
import TelephonePage from "./TelephonePage";

const RegisterPage = (props) => {
  const [userData, setUserData] = useState({});
  const [step, setStep] = useState("telephone");
  const [token, setToken] = useState(null);

  const registerData = useSelector(state => state?.auth?.registerData);

  useEffect(() => {
    if (Object.keys(registerData).length > 0) {
      updateUserData(registerData);
      setStep("confirmation");
    }
  }, [registerData]);

  const updateUserData = (data) => {
    setUserData({
      ...userData,
      ...data,
    });
  };

  const handleOnRegister = (data) => {
    setToken(data);
    setStep("success");
  };

  return (
    <>
      {["driving", "occupation", "personal"].includes(step) && (
        <ProgressTracker
          steps={[
            {
              value: "driving",
              label: "Driving information",
              icon: <DrivingIcon height={26} width={30} className="stroke" />,
              completed: true,
            },
            {
              value: "occupation",
              label: "Occupation",
              icon: <OccupationIcon height={26} className="fill" />,
              completed: ["occupation", "personal"].includes(step),
            },
            {
              value: "personal",
              label: "Personal information",
              icon: <PersonalIcon height={26} className="stroke" />,
              completed: step === "personal",
            },
          ]}
          onClick={(step) => setStep(step.value)}
        />
      )}

      {step === "telephone" ? (
        <TelephonePage
          onProceed={(e) => setStep("confirmation")}
          onUpdate={updateUserData}
        />
      ) : step === "confirmation" ? (
        <ConfirmationCodePage
          data={userData}
          onProceed={(e) => setStep("driving")}
          onUpdate={updateUserData}
          showLogin={false}
        />
      ) : step === "driving" ? (
        <DrivingInfoPage
          data={userData}
          onProceed={(e) => setStep("occupation")}
          onUpdate={updateUserData}
        />
      ) : step === "occupation" ? (
        <OccupationPage
          data={userData}
          onProceed={(e) => setStep("personal")}
          onUpdate={updateUserData}
        />
      ) : step === "personal" ? (
        <PersonalInfoPage
          data={userData}
          onProceed={(e) => setStep("review")}
          onUpdate={updateUserData}
          mode="register"
        />
      ) : step === "review" ? (
        <ReviewDetailsPage
          data={userData}
          onProceed={(e) => setStep("legals")}
          setStep={setStep}
        />
      ) : step === "legals" ? (
        <LegalStuffPage onProceed={handleOnRegister} />
      ) : step === "success" ? (
        <SuccessPage token={token} />
      ) : (
        <></>
      )}
    </>
  );
};

export default RegisterPage;
