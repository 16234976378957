import React, { useEffect, useState } from "react";

import PersonalInfo from "components/forms/profile/PersonalInfo";

const PersonalInfoPage = (props) => {
  return (
    <PersonalInfo { ...props } />
  );
};

export default PersonalInfoPage;
