import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { getDealersList } from "services";
import { useDataLoading } from "components/loader/LoaderHook";
import Loader from "components/Loader";
import PageTitle from "components/misc/PageTitle";
import RetailerCard from "components/retailers/RetailerCard";

const Retailers = () => {
  const { loading, setLoading } = useDataLoading(true);

  const [retailers, setRetailers] = useState([]);

  useEffect(() => {
    getDealersList().then((res) => {
      setRetailers(res.data);
      setLoading(false);
    });
  }, []);

  return (
    <div
      className={`dash-content no-scroll my-offers-content ${
        loading ? "flex items-center justify-center h-100" : ""
      }`}
    >
      {loading ? (
        <Loader size={120} />
      ) : (
        <>
          <PageTitle
            title="Retailers"
          />
          <div className="retailers-grid">
            {retailers.map((retailer) => (
              <RetailerCard key={retailer.id} retailer={retailer} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Retailers;
