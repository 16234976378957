import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { loginSuccessful } from "store/actions/auth";

import { ReactComponent as SmallLogo } from "assets/images/logo_small.svg";

const SuccessPage = (props) => {
  const dispatch = useDispatch();

  const login = () => {
    dispatch(loginSuccessful(props.token.token));
  }

  return (
    <div className="guest-page middle">
      <div className="bg">
        <div className="guest-title">
          That’s all done
        </div>
        <div className="guest-page-info">
          Thank you for providing your details, we don’t need anything else now and you can go ahead and view the car dealers we have available!
        </div>

        <button className="full light-blue with-icon" onClick={login}>
          Go to car dealers
          <SmallLogo />
        </button>
      </div>
    </div>
  );
}

export default SuccessPage;
