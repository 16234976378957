import React from "react";

const Introduction = (props) => {
  return (
    <div className={`${props.mobile ? "block md:hidden" : "hidden md:block"}`}>
      <h4 className="info-header">
        { props.mobile ? (
          <>{ props.name }, here are your <span>pre-approved</span> offers</>
        ) : (
          <>{props.name}, you’ve been <span>pre-approved</span> for the following offers</>
        )}
      </h4>
 
      <p className="info-text">
        Being pre-approved means you've already passed some of the
        initial checks set by the lender, and if all the details you
        have provided are correct, and you pass the full checks of the
        lender, you'll be granted the loan.
      </p>

      <p className="warning-text">
        All offers are valid for 30 days and are subject to approval and
        acceptance by the lender.
      </p>
   </div>
  );
}

export default Introduction;
