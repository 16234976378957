import authTypes from "store/types/auth";

export const loginSuccessful = (data) => ({
  type: authTypes.LOGIN_SUCCESSFUL,
  payload: data,
});

export const setUser = (data) => ({
  type: authTypes.SET_USER,
  payload: data,
});

const logoutSuccessful = () => ({
  type: authTypes.LOGOUT,
});

export const setRegisterData = (data) => ({
  type: authTypes.SET_REGISTER_DATA,
  payload: data,
});

export const setLoginData = (data) => ({
  type: authTypes.SET_LOGIN_DATA,
  payload: data,
});

export const logout = () => async (dispatch) => {
  await dispatch(logoutSuccessful());
}
